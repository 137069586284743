import { useEffect, useState } from 'react'
import StoryItem from '../../components/StoryItem'
import './index.css'

import plusBlue from '../../assets/icons/plus-blue.svg'

import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import miami from '../../assets/icons/miami.svg'
import noPicture from '../../assets/icons/no-picture.svg'
import CreateContrastDialog from '../../components-v2/CreateContrastDialog/CreateContrastDialog'
import { createPosts } from '../../redux/posts/actions'
import EntryDialog from '../../components-v2/EntryDialog/EntryDialog'
import { getAllTopics } from '../../redux/topics/actions'

const HomeStories = ({
  user,
  userId,
  setSelectTopicPostCreateModalVisible,
  onCreatePosts,
  getAllTopics,
}: any) => {
  const navigate = useNavigate()
  const image = 'https://oceanservice.noaa.gov/news/feb17/containership.jpg'
  const { imageUrl: profilePicture = image } = user

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          flex: 58,
          display: 'flex',
          flexWrap: 'nowrap',
          overflowX: 'auto',
          justifyContent: 'center',
        }}
        className="home_stories_ipad_view home_stories_mobile_view"
      >
        {/* <StoryItem
          imageUrl={plusBlue}
          title={'Add Post'}
          onClick={() => {
            setSelectTopicPostCreateModalVisible(true)
          }}
        /> */}
        <CreateContrastDialog
          trigger={<StoryItem imageUrl={plusBlue} title={'Add Post'} />}
          postTypes={['Fact', 'Research', 'Opinion', 'Rumor', 'Humor']}
          onClickPost={async (values) => {
            // console.log('🚀 ~ onClickPost={ ~ values:', values)
            await onCreatePosts(
              values.topicId,
              values.postType,
              values.description,
              values.postImageUrl,
              // clickedPost ? clickedPost.id : null,
              null,
              values.organizationId,
              values.locationObj?.id,
              values.locationObj?.name,
              values.locationObj?.geojson,
              values.locationObj?.address,
              values.locationObj?.addresstype
            )
          }}
          dialogType="post"
        />

        <EntryDialog
          cb={getAllTopics}
          trigger={
            <div className="bg-red-400 rounded-full flex items-center justify-center px-3 cursor-pointer">
              Trigger
            </div>
          }
        />

        <StoryItem
          imageUrl={profilePicture}
          title={'Your angle'}
          onClick={() => {
            navigate(`/user/${userId}`)
          }}
        />

        <StoryItem imageUrl={noPicture} title={'For You'} />
        <StoryItem imageUrl={miami} title={'Local'} />
      </div>
      {windowSize.innerWidth >= 1577 ? <div style={{ flex: 42 }}></div> : null}
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    userId: state.login.user.id,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllTopics: () => {
      dispatch(getAllTopics())
    },
    onCreatePosts: (
      topicId: string,
      postTypeId: string,
      description: string,
      mediaId?: any,
      contrasts?: string,
      organizationId?: string,
      placeID?: any,
      locationName?: any,
      geoJson?: any,
      address?: any,
      addressType?: any
    ) => {
      dispatch(
        createPosts(
          topicId,
          postTypeId,
          description,
          mediaId,
          contrasts,
          organizationId,
          placeID,
          locationName,
          geoJson,
          address,
          addressType
        )
      )
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeStories)

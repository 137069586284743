import React, { useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import HomeScreen from '../HomeScreen'
import TopicScreen from '../TopicScreen'
import TopicScreenPublic from '../TopicScreen/public_index'
import Login from '../Login'
import SignUp from '../SignUp'
import OrganizationCreate from '../OrganizationCreate'

import TopicCreateModal from '../TopicCreateModal'
import PostCreateModal from '../PostCreateModal'
import TrustFeedScreen from '../TrustFeedScreen'
import ContrastsCreateModal from '../ContrastsCreateModal'
import SelectTopicPostCreateModal from '../SelectTopicPostCreateModal'
import UserTypeModal from '../../components/UserTypeModal'
import UserTypeNoticeModal from '../../components/UserTypeNoticeModal'
import AddMemberToOrgModal from '../AddMemberToOrgModal'
import DeleteMemberToOrgModal from '../DeleteMemberToOrgModal'
import PostEditDeleteReasonModal from '../../components/PostEditDeleteReasonModal'

import HomeContainer from '../../components/HomeContainer'
import HomeContainerOld from '../../components/HomeContainer/index_old'
import { Provider, useSelector } from 'react-redux'
import store from '../../redux/store'
import ArticleCreateModal from '../ArticleCreateModal'
import PostScreen from '../../containers/PostScreen'
import PostScreen_old from '../../containers/PostScreen/index_old'
import ArticleScreen from '../ArticleScreen'
import UserScreen from '../UserScreen-v2'
import OrganizationScreen from '../OrganizationScreen'
import TopicArticleCreateModal from '../TopicArticleCreateModal'
import OnBoarding from '../../components/OnBoarding'
import SignUpEmail from '../../containers/SignUpEmail'
import ProfileSetup from '../../containers/ProfileSetup'
import AcceptTerms from '../../containers/AcceptTerms'
import CreateOrganization from '../CreateOrganization'
import OrganizationDetails from '../OrganizationDetails'
import UserDetails from '../UserDetails'
import EditUserDetails from '../EditUserDetails'
import EditOrganizationPage from '../EditOrganizationPage'
import LandingPage from '../../components/LandingPage'
import ErrorDisplayModal from '../../components/ErrorDisplayModal'
import CreateHouseguest from '../CreateHouseguest/CreateHouseguest'
import TrustFeed from '../../components-v2/TrustFeed/TrustFeed'
import TermsOfService from '../TermsOfService/TermsOfService'
import { isAuthenticated as isAuthenticatedFn } from '../../utils/auth'
import ForgotPasssordScreen from '../ForgotPasswordScreen/ForgotPasssordScreen'
import ResetPasswordPage from '../ResetPasswordPage/ResetPasswordPage'
import ConfirmEmailPage from '../ConfirmEmailPage/ConfirmEmailPage'
import SignupPage from '../SignupPage/SignupPage'
import PublicPostPage from '../PostScreen/PublicPostPage'
import NotificationsPage from '../NotificationsPage/NotificationsPage'
import EmailPreference from '../Settings/EmailPreference'

const AppContainer = () => {
  const [articleModalVisible, setArticleModalVisible] = useState(false)
  const [topicArticleModalVisible, setTopicArticleModalVisible] =
    useState(false)

  const [topicModalVisible, setTopicModalVisible] = useState(false)
  const [postModalVisible, setPostModalVisible] = useState(false)
  const [contrastsModalVisible, setContrastsModalVisible] = useState(false)
  const [
    selectTopicPostCreateModalVisible,
    setSelectTopicPostCreateModalVisible,
  ] = useState(false)
  const [addMemberToOrgModalVisible, setAddMemberToOrgModalVisible] =
    useState(false)
  const [deleteMemberToOrgModalVisible, setDeleteMemberToOrgModalVisible] =
    useState(false)
  const [editPostModalVisible, setEditPostModalVisible] = useState(false)

  // const [userTypeNoticeModalVisible,setUserTypeNoticeModalVisible] = useState(false)

  const [selectUserTypeModalVisible, setSelectUserTypeModalVisible] =
    useState(false) //modal for user typ in onboarding

  const [paramForArticleModal, setParamsForArticleModal] = useState({})

  const [pageArticle, setPageArticle] = useState(0)
  const [userType, setUserType] = useState('')

  const user = useSelector((state: any) => state.login.user)

  const isAuthenticated = !!user || isAuthenticatedFn()

  // @ts-ignore
  // @ts-ignore
  return (
    <div style={{ flexDirection: 'column', display: 'flex', height: '100%' }}>
      {/* <Provider store={store}> */}
      <BrowserRouter>
        <Routes>
          {/*protect routes */}
          <Route
            path={'/'}
            element={
              <HomeScreen
                setPostModalVisible={setPostModalVisible}
                setTopicModalVisible={setTopicModalVisible}
                setArticleModalVisible={setArticleModalVisible}
                setTopicArticleModalVisible={setTopicArticleModalVisible}
                setParamsForArticleModal={setParamsForArticleModal}
              />
            }
          >
            {/* <Route
                index
                element={
                  <HomeContainer
                    editPostModalVisible={editPostModalVisible}
                    setEditPostModalVisible={setEditPostModalVisible}
                    setPostModalVisible={setPostModalVisible}
                    setSelectTopicPostCreateModalVisible={
                      setSelectTopicPostCreateModalVisible
                    }
                  />
                }
              /> */}

            {/* TODO: Remove below */}
            <Route
              path={'/old_home'}
              index
              element={
                <HomeContainerOld
                  editPostModalVisible={editPostModalVisible}
                  setEditPostModalVisible={setEditPostModalVisible}
                  setPostModalVisible={setPostModalVisible}
                  setSelectTopicPostCreateModalVisible={
                    setSelectTopicPostCreateModalVisible
                  }
                />
              }
            />

            {/* <Route
                path={'/topic/:topicId/post/:postId'}
                element={
                  <PostScreen
                    setPageArticle={setPageArticle}
                    setArticleModalVisible={setArticleModalVisible}
                    setPostModalVisible={setPostModalVisible}
                  />
                }
              /> */}
            <Route
              path={'/topic/:topicId/post/:postId/old_version'}
              element={
                <PostScreen_old
                  setPageArticle={setPageArticle}
                  setArticleModalVisible={setArticleModalVisible}
                  setPostModalVisible={setPostModalVisible}
                />
              }
            />
            <Route
              path={'/topic/:topicId/article/:articleId'}
              element={
                <ArticleScreen
                  setArticleModalVisible={setArticleModalVisible}
                  setPostModalVisible={setPostModalVisible}
                />
              }
            />
            <Route path={'/trust'} element={<TrustFeed />} />
            <Route path={'/adduser'} element={<SignUp />} />
            <Route path={'/create-houseguest'} element={<CreateHouseguest />} />
            <Route
              path={'/organizationcreate'}
              element={<OrganizationCreate />}
            />
            <Route
              path={'/organization/:orgId'}
              element={<OrganizationScreen />}
            />
            <Route path={'/user/:userId'} element={<UserScreen />} />
            <Route
              path={'/createorganization'}
              element={<CreateOrganization />}
            />
            <Route
              path={'/organizationdetails/:orgId'}
              element={
                <OrganizationDetails
                  setAddMemberToOrgModalVisible={setAddMemberToOrgModalVisible}
                  setDeleteMemberToOrgModalVisible={
                    setDeleteMemberToOrgModalVisible
                  }
                />
              }
            />
            <Route path={'/profiledetails'} element={<UserDetails />} />
            <Route path={'/notifications'} element={<NotificationsPage />} />
            <Route path={'/editprofiledetails'} element={<EditUserDetails />} />
            <Route
              path={'/editorganizationdetails/:orgId'}
              element={<EditOrganizationPage />}
            />
          </Route>

          {isAuthenticated ? (
            <Route
              path={'/topic/:topicId'}
              element={
                <TopicScreen
                  setPageArticle={setPageArticle}
                  pageArticle={pageArticle}
                  setArticleModalVisible={setArticleModalVisible}
                  setPostModalVisible={setPostModalVisible}
                  setSelectTopicPostCreateModalVisible={
                    setSelectTopicPostCreateModalVisible
                  }
                  setParamsForArticleModal={setParamsForArticleModal}
                />
              }
            ></Route>
          ) : (
            <Route
              path={'/topic/:topicId'}
              element={<TopicScreenPublic />}
            ></Route>
          )}

          {isAuthenticated ? (
            <Route
              path={'/topic/:topicId/post/:postId'}
              element={
                <PostScreen
                  setPageArticle={setPageArticle}
                  setArticleModalVisible={setArticleModalVisible}
                  setPostModalVisible={setPostModalVisible}
                />
              }
            />
          ) : (
            <>
              {/* <Route
                path={'/topic/:topicId/post/:postId'}
                element={<div>adasds</div>}
                ></Route> */}
              <Route
                path={'/topic/:topicId/post/:postId'}
                element={<PublicPostPage />}
              />
            </>
          )}

          <Route
            path={'/settings/email/:token'}
            element={<EmailPreference />}
          />

          <Route
            path={'/forgot-password'}
            element={<ForgotPasssordScreen />}
          ></Route>

          <Route
            path={'/reset-password'}
            element={<ResetPasswordPage />}
          ></Route>

          <Route path={'/confirm-email'} element={<ConfirmEmailPage />}></Route>

          <Route
            path={'/signup/'}
            element={<Navigate to={'/signup/methods'} />}
          />
          <Route path={'/signup/:tab'} element={<SignupPage />} />

          <Route
            path="/"
            index
            element={
              <HomeContainer
                editPostModalVisible={editPostModalVisible}
                setEditPostModalVisible={setEditPostModalVisible}
                setPostModalVisible={setPostModalVisible}
                setSelectTopicPostCreateModalVisible={
                  setSelectTopicPostCreateModalVisible
                }
              />
            }
          />

          {/*public routes */}
          <Route path={'/landing'} element={<LandingPage />} />
          <Route path={'/login'} element={<Login />} />

          {/* Remove below line later */}
          <Route path={'/signup2'} element={<OnBoarding />} />
          <Route path={'/signupemail'} element={<SignUpEmail />} />
          <Route
            path={'/profilesetup'}
            element={
              <ProfileSetup
                setSelectUserTypeModalVisible={setSelectUserTypeModalVisible}
                userTypeValue={userType}
              />
            }
          />
          <Route path={'/acceptterms'} element={<AcceptTerms />} />
          <Route path={'/terms-of-service'} element={<TermsOfService />} />
        </Routes>

        {topicModalVisible && (
          <TopicCreateModal setTopicModalVisible={setTopicModalVisible} />
        )}
        {postModalVisible && (
          <PostCreateModal setPostModalVisible={setPostModalVisible} />
        )}

        {/*modal for edit post*/}
        {editPostModalVisible && (
          <PostCreateModal
            editPostModalVisible={editPostModalVisible}
            setPostModalVisible={setEditPostModalVisible}
            editPost={true}
          />
        )}
        {articleModalVisible && (
          <ArticleCreateModal
            setPostModalVisible={setPostModalVisible}
            setArticleModalVisible={setArticleModalVisible}
            paramForArticleModal={paramForArticleModal}
          />
        )}
        {topicArticleModalVisible && (
          <TopicArticleCreateModal
            setPostModalVisible={topicArticleModalVisible}
            setArticleModalVisible={setTopicArticleModalVisible}
            paramForArticleModal={paramForArticleModal}
          />
        )}
        {contrastsModalVisible && (
          <ContrastsCreateModal
            setContrastsModalVisible={setContrastsModalVisible}
          />
        )}
        {selectTopicPostCreateModalVisible && (
          <SelectTopicPostCreateModal
            setSelectTopicPostCreateModalVisible={
              setSelectTopicPostCreateModalVisible
            }
          />
        )}
        {selectUserTypeModalVisible && (
          <UserTypeModal
            setSelectUserTypeModalVisible={setSelectUserTypeModalVisible}
            setValue={setUserType}
          />
        )}
        {addMemberToOrgModalVisible && (
          <AddMemberToOrgModal
            setAddMemberToOrgModalVisible={setAddMemberToOrgModalVisible}
            addMemberToOrgModalVisible={addMemberToOrgModalVisible}
          />
        )}
        {deleteMemberToOrgModalVisible && (
          <DeleteMemberToOrgModal
            setDeleteMemberToOrgModalVisible={setDeleteMemberToOrgModalVisible}
            deleteMemberToOrgModalVisible={deleteMemberToOrgModalVisible}
          />
        )}
      </BrowserRouter>
      {/* </Provider> */}
    </div>
  )
}

export default AppContainer

import React from 'react'
import { termsOfServiceHTML } from './termsOfServiceHTML'
import PublicHeader from '../../components-v2/Header/PublicHeader'

type Props = {}

export default function TermsOfService({}: Props) {
  return (
    <div>
      <PublicHeader />
      <div className="max-w-[800px] mx-auto py-5 px-5">
        <div dangerouslySetInnerHTML={{ __html: termsOfServiceHTML }}></div>
      </div>
    </div>
  )
}

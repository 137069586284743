import React, { useState } from 'react'
import AddSVG from '../../assets/icons/add.svg'
import { createPosts } from '../../redux/posts/actions'
import CreateContrastDialog from '../../components-v2/CreateContrastDialog/CreateContrastDialog'
import { useDispatch } from 'react-redux'
import { cn } from '../../utils/cn'
import EntryDialog from '../../components-v2/EntryDialog/EntryDialog'
import TopicFollowButton from '../../components-v2/TopicFollowButton'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../components-v2/shadcn/dropdown-menu'
import { MoreVertical } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { locationToDisplay } from '../../components-v2/Post/helpers'
import AddTopic from '../../components-v2/EntryDialog/dialogs/AddTopic/AddTopic'
import EditTopic from '../../components-v2/EntryDialog/dialogs/EditTopic/EditTopic'
import EditLocation from '../../components-v2/EntryDialog/dialogs/EditLocation/EditLocation'

type Props = {
  title: string
  location: any
  description: string
  topicId?: string
  className?: string
  permissions?: any
  cb?: () => void
  onNoAuthCallback?: () => void
  isTopicModerator?: boolean
  isLocationModerator?: boolean
  locationDetails?: any
}

export default function DescriptionCard({
  description,
  location,
  title,
  topicId,
  className,
  permissions,
  cb,
  onNoAuthCallback,
  isTopicModerator,
  isLocationModerator,
  locationDetails,
}: Props) {
  const navigate = useNavigate()
  const [openTopic, setOpenTopic] = useState(false)
  const [openLocation, setOpenLocation] = useState(false)
  return (
    <div>
      <div
        className={cn(
          'flex flex-col bg-line px-7 py-7 mx-desktop:px-3 mx-desktop:py-3',
          className
        )}
      >
        <div className="flex items-center justify-between">
          <p className="text-sm font-semibold">{title}</p>

          <div className="flex items-center gap-2">
            {topicId && topicId !== 'local' && (
              <TopicFollowButton topicId={topicId} />
            )}

            <DropdownMenu>
              <DropdownMenuTrigger>
                <div className="rounded-md p-[2px] text-black transition-all hover:bg-border">
                  <MoreVertical size={16} />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  className="text-xs"
                  onClick={() => {
                    const url = `${window.location.origin}/topic/${topicId}`
                    navigator.clipboard.writeText(url).then(() => {
                      toast.success(`Topic url copied to your clipboard`, {
                        description: url,
                      })
                    })
                  }}
                >
                  Share: Copy link to clipboard
                </DropdownMenuItem>
                {(isTopicModerator || isLocationModerator) && (
                  <DropdownMenuItem
                    className="text-xs"
                    onClick={() => {
                      isTopicModerator && setOpenTopic(true)
                      isLocationModerator && setOpenLocation(true)
                    }}
                  >
                    Edit
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        {topicId === 'local' && (
          <p className="text-xs mt-[1]">
            <span>{location}</span>
          </p>
        )}
        {/*<p className="text-xs">{locationToDisplay(location)}</p>*/}
        <p className="mt-[20px] text-xs">{description}</p>
      </div>

      {/* <PostCreationForTopic
        parentTopicId={topicId || ''}
        trigger={
          <div className="h-[25px] px-12 mx-desktop:px-3 mx-mobile:w-full bg-[#F5F5F5] hover:bg-line/50 transition-all rounded-b-lg flex items-center  gap-[6px] cursor-pointer">
            <img src={AddSVG} className="h-4 w-4" />
            <p className="text-[11px] text-border">Create content</p>
          </div>
        }
      /> */}

      {onNoAuthCallback ? (
        <div
          onClick={onNoAuthCallback}
          className="flex h-[28px] cursor-pointer items-center gap-[6px] rounded-b-lg border border-line bg-[#F5F5F5] px-7 transition-all hover:bg-line/50 mx-desktop:px-3 mx-mobile:w-full"
        >
          <img src={AddSVG} className="h-4 w-4" />
          <p className="text-[11px] text-border">Create content</p>
        </div>
      ) : (
        <EntryDialog
          disableTopic={permissions?.permissions?.topicCreation}
          articleAllow={permissions?.permissions?.articleCreation}
          quotesAllow={permissions?.permissions?.quotesCreation}
          trigger={
            <div className="flex h-[28px] cursor-pointer items-center gap-[6px] rounded-b-lg border border-line bg-[#F5F5F5] px-7 transition-all hover:bg-line/50 mx-desktop:px-3 mx-mobile:w-full">
              <img src={AddSVG} className="h-4 w-4" />
              <p className="text-[11px] text-border">Create content</p>
            </div>
          }
          parentTopicId={topicId}
          cb={cb}
        />
      )}

      {/* <CreateContrastDialog
        postTypes={['Fact', 'Research', 'Opinion', 'Rumor', 'Humor']}
        onClickPost={async (values) => {
          createPosts(
            values.topicId,
            values.postType,
            values.description,
            values.postImageUrl,
            // clickedPost ? clickedPost.id : null,
            undefined,
            values.organizationId,
            values.locationObj?.id,
            values.locationObj?.name,
            values.locationObj?.geojson,
            values.locationObj?.address,
            values.locationObj?.addresstype
          )(dispatch)
        }}
        dialogType="post"
        trigger={
          <div className="h-[25px] px-12 mx-mobile:px-5 mx-mobile:w-full bg-[#F5F5F5] hover:bg-line/50 transition-all rounded-b-lg flex items-center  gap-[6px] cursor-pointer">
            <img src={AddSVG} className="h-4 w-4" />
            <p className="text-[11px] text-border">Create content</p>
          </div>
        }
        parentTopicId={topicId}
      /> */}
      <EditTopic
        cb={cb}
        setOpen={setOpenTopic}
        open={openTopic}
        topicId={topicId}
      />

      <EditLocation
        open={openLocation}
        setOpen={setOpenLocation}
        topicId={location}
        locationId={locationDetails?.id}
      />
    </div>
  )
}

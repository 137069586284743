import authAxios from '../utils/authAxios'
import { URLMetadata } from './article.types'

export async function addDailyPost(postId: string) {
  const res = await authAxios.post(`/v2/emailDigest/addDailyPost`, {
    postId,
  })

  return res.data
}

import axios from 'axios'
import authAxios, { publicAxios } from '../utils/authAxios'
import { URLMetadata } from './article.types'

export async function resendEmailVerification() {
  const res = await authAxios.get(`/v2/auth/resendEmailVerification`)

  return res.data
}

export async function changePassword(values: any) {
  const res = await authAxios.post(`/v2/auth/changePassword`, values)

  return res.data
}

export async function doesEmailExist(email: string) {
  const res = await publicAxios.post(`/v2/auth/doesEmailExist`, { email })

  return res?.data?.isValid
}

export async function signupOld(values: any) {
  console.log('🚀 ~ signup ~ values:', values)
  const payload = {
    firstName: values.firstName,
    lastName: values.lastName,
    description: values.about,
    verified: false,
    public: false,
    imageUrl: values.profileImgSrc,
    location: values.homeTown,
    email: values.email,
    password: values.password,
    phoneNumber: values.phoneNumber,
    birthday: values.dob,
    terms: true,
    verificationInfo: values.verificationInfo,
    userTypeId: values.userType,
  }
  console.log('🚀 ~ signup ~ payload:', payload)
  const res = await publicAxios.post(`/v2/onboarding/usercreate`, payload)

  return res.data
}

export async function signup(values: any) {
  const res = await publicAxios.post(`/v2/auth/signup`, values)

  return res.data
}

export async function requestANewUserType(userType: string) {
  const res = await authAxios.post(`/v2/auth/requestANewUserType`, { userType })

  return res.data
}

export async function canLocationFind(locationObject: any) {
  const res = await publicAxios.post(`/v2/location/doesLocationExist`, {
    addresObject: locationObject,
  })

  return res.data
}

import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent } from '../../../shadcn/dialog'
import { Input } from '../../../shadcn/input'
import { PlusCircle, X } from 'lucide-react'
import Button from '../../../Button'
import PartyCard from '../AddTopic/PartyCard'
import { cn } from '../../../../utils/cn'
import UserSearchDialog from '../AddTopic/UserSearchDialog'
import { toast } from 'sonner'
import { Popover, PopoverTrigger } from '../../../shadcn/popover'
import {
  getLocationDetailsForEdit,
  editLocationDetails,
} from '../../../../services/location'

type DialogProps = {
  open: boolean
  setOpen: (isOpen: boolean) => void
  cb?: () => void
  topicId?: any
  locationId?: object
}

export default function EditLocation(props: DialogProps) {
  const { open, setOpen } = props
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        className="max-h-screen max-w-[600px] overflow-y-auto px-0"
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
      >
        <Content {...props} />
      </DialogContent>
    </Dialog>
  )
}

function Content({ setOpen, cb, topicId }: DialogProps) {
  const [moderatorOpen, setModeratorOpen] = useState(false)
  const [topicDetails, setTopicDetails] = useState<Record<string, any>>({})
  const [moderators, setModerators] = useState<string[]>([])
  const [locationId, setLocationId] = useState('')

  useEffect(() => {
    getLocationDetailsForEdit(topicId).then((data) => {
      setModerators(data?.moderators)
      setLocationId(data?.locationId)
    })
  }, [topicDetails?.description])

  const [topicOpen, setTopicOpen] = useState<boolean>(false)

  return (
    <>
      <form className="px-6">
        <p>
          Edit{' '}
          <span className="font-bold underline underline-offset-4">
            LOCATION
          </span>
        </p>

        <div className="mt-5 flex flex-col gap-8">
          <div className="">
            <label className="text-xs font-bold">Topic Name*</label>
            <Input
              className="mt-1 h-[29px] border-line text-xs placeholder:text-[11px] placeholder:italic placeholder:text-line"
              placeholder="Name"
              defaultValue={'Local News'}
              disabled={true}
            />
          </div>

          <div className="">
            <label className="text-xs font-bold">Location*</label>
            <Popover open={topicOpen} onOpenChange={setTopicOpen}>
              <PopoverTrigger asChild>
                <button className={cn('mt-1 w-full cursor-pointer text-left')}>
                  <div className=" bg-whit rounded-md border-2 p-1 text-sm __ring-offset-white text-gray-500 pl-3">
                    {topicId}
                  </div>
                </button>
              </PopoverTrigger>
            </Popover>
          </div>
        </div>
      </form>

      <div className="bg-line flex justify-between items-center px-6 h-[52px] mt-5">
        <span className="font-semibold text-base">Moderators</span>

        <UserSearchDialog
          title="Moderator search"
          onlyVerified={false}
          forLocationPurpose={true}
          open={moderatorOpen}
          setOpen={setModeratorOpen}
          trigger={<PlusCircle size={24} className="cursor-pointer" />}
          onSelectClick={(userId) => {
            const alreadyExists = !!moderators.find((x) => x === userId)

            if (alreadyExists) {
              toast.error('Moderator already exists')
              return
            }

            setModerators((prevModerators) => [...prevModerators, userId])
            setModeratorOpen(false)
          }}
        />
      </div>

      <div className="mx-6 flex flex-col gap-[19px]">
        {moderators?.length < 1 && (
          <p className="text-xs text-border">No moderators</p>
        )}
        {moderators?.map((x: any) => {
          return (
            <PartyCard
              profileImageUrl={x?.imageUrl}
              userId={x}
              action={
                <X
                  size={20}
                  className="cursor-pointer hover:text-red-500 transition-all"
                  onClick={() => {
                    const newArr = moderators.filter((p) => p !== x)
                    setModerators(newArr)
                  }}
                />
              }
            />
          )
        })}
      </div>

      {/* Actions */}
      <div className="mt-5 flex items-center justify-between px-6">
        <Button
          onClick={() => {
            setOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={false}
          type="submit"
          onClick={async () => {
            try {
              await editLocationDetails(locationId, moderators)
              setOpen(false)
              toast.success(`location Updated`)
            } catch (e) {
              toast.error('Something went wrong')
            }
          }}
        >
          Edit
        </Button>
      </div>
    </>
  )
}

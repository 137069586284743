import authAxios from '../../utils/authAxios'

export async function getNiooze({ postType, org, page }: any) {
  console.log('Fetching', { postType, org, page })

  const res = await authAxios.post(`v2/post/getNiooze`, {
    // users: 'all',
    // discovery: 'all',
    // period: '24h',
    // impressions: 'all',
    // sorting: 'all',
    pagination: {
      page: page,
      limit: 4,
    },
    organisationType: org,
    postType: postType,
  })

  // console.log('Response', res.data)

  return res.data
}

export const getNextPageParam = (pageResponse: any) => {
  // const currentPage = pageResponse.page
  // const totalHits = pageResponse.found
  // const perPage = 4

  // const totalPages = Math.ceil(totalHits / perPage)

  return pageResponse?.next?.page ? pageResponse?.next?.page : undefined
}

export async function getNioozeWrapper({ queryKey, pageParam }: any) {
  const [key, filterPostType, filterOrganization] = queryKey

  const res = await getNiooze({
    postType: filterPostType,
    org: filterOrganization,
    page: pageParam,
  })

  return res
}

import './index.css'
import Spacer from '../Spacer'
import ButtonComponent from '../ButtonComponent'
import text from '../../assets/icons/text.svg'
import dropDown from '../../assets/icons/drop-down-arrow.svg'
import upDown from '../../assets/icons/up-down-arrow.svg'
import contrastIco from '../../assets/icons/contrast.svg'
import greenBell from '../../assets/icons/green-bell.svg'
import bell from '../../assets/icons/bell.svg'
import VerifiedIconSVG from '../../assets/icons/verified-blue.svg'
import PublicIconSVG from '../../assets/icons/public-yellow.svg'
import { getRandomNumber } from '../../utils'
import comments from '../../assets/icons/comments.svg'
import moreMenu from '../../assets/icons/more-menu.svg'
import React, { useEffect, useState } from 'react'
import arrowUp from '../../assets/icons/arrow-up.svg'
import { useInView } from 'react-intersection-observer'
import AnalyticsTrackerWrapper from '../../containers/AnalyticsTrackerWrapper'
import ArrowUpSVG from '../../assets/icons/arrow-up.svg'
import { cn } from '../../utils/cn'
import { MoreVertical } from 'lucide-react'
import { alarmToggle, upvoteToggle } from '../../components-v2/Post/services'
import { useNavigate } from 'react-router-dom'
import { getHowLongAgo } from '../../components-v2/Post/helpers'
const TrustFeed = (props: any) => {
  const {
    post,
    visual,
    sentence,

    editedPostType,
    editedPostContent,
    editedPostMedia,

    userName,
    userProfession,
    changedBy,

    postType,
    postContent,
    postMedia,

    isEditType = false,
    isEditContent = false,
    isDeleted = false,

    changedTime,
    reason,
    topic,
    vote,
    userId,
    userProfile,
    alarmToggleTrustFeedChanges,
    addPostsToRedux,
    editedPostId,
    topicId,
    postId,
    editor,
    //only type is changed
  } = props
  const { id } = post || {}
  const navigate = useNavigate()

  const [moreDetails, setMoreDetails] = useState(false)
  const href = `/topic/${topicId === null ? 'local' : topicId}/post/${postId}`

  const { ref, inView } = useInView({
    threshold: 2 / 3,
    triggerOnce: true,
    delay: 3000,
    trackVisibility: false,
  })

  const [onScroll, setOnScroll] = useState(false)
  useEffect(() => {
    const onScrolling = setTimeout(() => {
      if (inView) {
        // updateAnalytics([id])
        addPostsToRedux({ postId: id, trustFeed: 'trust changes' })
        if (isEditType)
          addPostsToRedux({ postId: editedPostId, trustFeed: 'trust changes' })
      }
    }, 2000)

    return () => clearTimeout(onScrolling)
  }, [
    onScroll,
    inView,
    //, inView, description, updateAnalytics, id, postIds
  ])

  return (
    <AnalyticsTrackerWrapper>
      <div ref={ref}>
        {' '}
        {/*Heading*/}
        <div
          className={
            'heading-text-feed heading-border heading-border-max-width center-div heading-flex-center mt-3'
          }
        >
          {visual}
        </div>
      </div>

      <div className={'second-heading-text'} style={{ marginTop: '5px' }}>
        {/*Second heading*/}
        {sentence}
      </div>

      {!(isEditType && !isEditContent) ? (
        <div className={'edited-post mt-5'} ref={ref}>
          {/*edited post*/}
          <div
            className={
              'edited-post-start padding-left-ten edited-post-type font-weight-600'
            }
          >
            {
              isEditType ? (
                <span className={'edited-post-line-through upper-case-letter'}>
                  {editedPostType}
                </span>
              ) : isDeleted ? (
                <span className={'edited-post-line-through upper-case-letter'}>
                  {editedPostType}
                </span> //if it is deleted and not edited type
              ) : (
                <span className={'upper-case-letter'}>{editedPostType}</span>
              ) //if it is edited type and not deleted
            }
          </div>
          <Spacer height={5} />
          <div className={'edited-post-border'}>
            {/*post content*/}
            <div
              className={
                'edited-post-start padding-left-ten edited-post-type text-xs font-semibold'
              }
            >
              {userName}
              <span className={'ml-0.5'}> ({getHowLongAgo(changedTime)}) </span>
              <div className="flex gap-[1px] mb-[01px] ml-1 opacity-10">
                {
                  <img
                    src={VerifiedIconSVG}
                    className="w-[15px] h-[15px] "
                    title="Verified"
                  />
                }

                {
                  <img
                    src={PublicIconSVG}
                    className="w-[15px] h-[15px] "
                    title="Public"
                  />
                }
              </div>
            </div>
            {/*editor name*/}
            <div
              className={
                'edited-post-start padding-left-ten edited-post-type flex gap-1'
              }
            >
              {userProfession}
            </div>
            {/*professional*/}
            <Spacer height={7} />
            <div
              className={
                'edited-post-start padding-left-ten text-align-left edited-post-content'
              }
            >
              {isDeleted ? (
                <span className={'edited-post-line-through'}>
                  {editedPostContent}
                </span>
              ) : (
                <span>{editedPostContent}</span>
              )}
            </div>

            <div
              className={
                'post-start padding-left-ten text-align-left post-content'
              }
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'end',
                justifyContent: 'flex-end',
                paddingRight: '12px',
                paddingBottom: '5px',
              }}
            >
              {editedPostMedia && (
                <img
                  src={editedPostMedia}
                  alt=""
                  style={{
                    objectFit: 'cover',
                    marginTop: 22,
                    borderRadius: 10,
                    width: '100%',
                    opacity: '0.4',
                    // height: 300
                  }}
                />
              )}
            </div>
            {/*post content*/}

            {isDeleted ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'end',
                  justifyContent: 'flex-end',
                }}
              >
                <ButtonComponent
                  onClick={(e: any) => {
                    e.stopPropagation()
                  }}
                >
                  <div
                    style={{ marginBottom: 1 }}
                    className={'action-container'}
                  >
                    {/*<img*/}
                    {/*  className={'action-img'}*/}
                    {/*  onClick={() => {*/}
                    {/*    alarmToggleTrustFeedChanges(id)*/}
                    {/*  }}*/}
                    {/*  src={greenBell}*/}
                    {/*  alt=""*/}
                    {/*/>*/}
                  </div>
                </ButtonComponent>

                <ButtonComponent>
                  <div className={'action-container'}>
                    <text className={'up-text'}>{getRandomNumber(0, 12)}</text>
                    <img className={'action-img'} src={contrastIco} alt="" />
                  </div>
                </ButtonComponent>

                <ButtonComponent>
                  <div className={'action-container'}>
                    <text className={'up-text'}>{getRandomNumber(0, 12)}</text>
                    <img className={'action-img'} src={comments} alt="" />
                  </div>
                </ButtonComponent>
                <div>
                  <ButtonComponent
                    onClick={(e: any) => {
                      e.stopPropagation()
                    }}
                  >
                    <div className={'action-container'}>
                      <MoreVertical size={16} />
                    </div>
                  </ButtonComponent>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {!isDeleted && (
        <div
          className={'post'}
          style={{ position: 'relative', marginTop: '20px' }}
        >
          {/*post*/}

          <div
            className={'absolute top-[-16px] right-[-8px]'}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <div
              className={cn(
                'flex flex-col border-border border w-[25px] h-[25px] rounded-lg items-center justify-center hover:bg-[#d3e6c6] transition-all'
              )}
              style={{
                backgroundColor: vote?.some(
                  (obj: any) => obj?.userId === userId
                )
                  ? '#D5E7C4'
                  : 'white',
              }}
              onClick={() => {
                upvoteToggle(id)
              }}
            >
              <img className={'w-4 h-4'} src={ArrowUpSVG} alt="" />
            </div>
          </div>

          <div
            className={'post-start padding-left-ten post-type font-weight-600'}
          >
            {isEditType && !isEditContent ? ( //if only post type is changed
              <p
                className={
                  'text-start ml-[12px] font-semibold text-xs text-text pb-[2px] uppercase mb-[-5px] ml-[1px]'
                }
              >
                <span
                  className={
                    'edited-post-type text-xs text-text font-semibold pb-[2px] edited-post-line-through font-weight-600 upper-case-letter'
                  }
                >
                  {editedPostType}
                </span>{' '}
                RUMOR
              </p>
            ) : (
              <p
                // className={
                //   'text-start ml-[1px] font-semibold text-xs text-text uppercase pb-[2px]'
                // }
                className={
                  'text-start ml-[1px] font-semi-bold text-xs text-text uppercase mb-[-3px]'
                  // 'text-start ml-[12px] font-semibold text-xs text-text pb-[2px] uppercase'
                }
              >
                {postType}
              </p>
            )}
          </div>
          <Spacer height={5} />
          <div className={'post-border'}>
            {/*post content*/}
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
              onClick={() => {
                // console.log('navigation')

                navigate(href)
              }}
            >
              <div>
                <div className={'flex'}>
                  <h3
                    className={
                      'post-start padding-left-ten post-type text-xs font-semibold flex items-center gap-1'
                    }
                  >
                    {userName}
                    <span className={'mr-0.5'}>
                      ({getHowLongAgo(post?.createdAt)})
                    </span>
                  </h3>

                  <div className="flex gap-[1px] mb-[01px]">
                    {
                      <img
                        src={VerifiedIconSVG}
                        className="w-[15px] h-[15px] "
                        title="Verified"
                      />
                    }

                    {
                      <img
                        src={PublicIconSVG}
                        className="w-[15px] h-[15px] "
                        title="Public"
                      />
                    }
                  </div>
                </div>
                <span
                  className={'post-start padding-left-ten text-xs text-subtext'}
                >
                  {userProfession}
                </span>
              </div>

              <div style={{ paddingRight: '15px' }}>
                <img
                  src={userProfile}
                  alt=""
                  style={{
                    objectFit: 'cover',
                    borderRadius: '30px',
                    width: '35px',
                    height: '35px',
                    // height: 300
                  }}
                />
              </div>
            </div>

            {/*editor name*/}
            {/*<div*/}
            {/*    className={'post-start padding-left-ten post-user-type font-weight-400'}>{userProfession}*/}
            {/*</div>*/}
            {/*professional*/}
            <Spacer height={7} />
            <div
              onClick={() => {
                // console.log('navigation')

                navigate(href)
              }}
              className={
                'post-start padding-left-ten text-align-left post-content     '
              }
            >
              {/*post content*/}
              {postContent}
            </div>

            <div
              onClick={() => {
                // console.log('navigation')

                navigate(href)
              }}
              className={
                'post-start padding-left-ten text-align-left post-content'
              }
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'end',
                justifyContent: 'flex-end',
                paddingRight: '12px',
              }}
            >
              {postMedia && (
                <img
                  src={postMedia}
                  alt=""
                  style={{
                    objectFit: 'cover',
                    marginTop: 22,
                    borderRadius: 10,
                    width: '100%',
                    // height: 300
                  }}
                />
              )}
            </div>

            <Spacer height={10} />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'end',
                justifyContent: 'flex-end',
              }}
            >
              <ButtonComponent
                onClick={(e: any) => {
                  e.stopPropagation()
                }}
              >
                <div style={{ marginBottom: 1 }} className={'action-container'}>
                  {post?.alarm ? (
                    <img
                      className={'"w-4 h-4"'}
                      onClick={() => {
                        alarmToggleTrustFeedChanges(id)
                      }}
                      src={greenBell}
                      alt=""
                    />
                  ) : (
                    <img
                      className={'"w-4 h-4"'}
                      onClick={() => {
                        alarmToggleTrustFeedChanges(id)
                      }}
                      src={bell}
                      alt=""
                    />
                  )}
                </div>
              </ButtonComponent>

              <ButtonComponent>
                <div className={'action-container'}>
                  <text
                    className={'text-[8px] text-center -mb-[2px] text-border'}
                  >
                    {getRandomNumber(0, 12)}
                  </text>
                  <img
                    className={'w-4 h-4 hover:bg-line rounded-lg'}
                    src={contrastIco}
                    alt=""
                  />
                </div>
              </ButtonComponent>

              <ButtonComponent>
                <div className={'action-container'}>
                  <text
                    className={'text-[8px] text-center -mb-[2px] text-border'}
                  >
                    {getRandomNumber(0, 12)}
                  </text>
                  <img className="w-4 h-4" src={comments} alt="" />
                </div>
              </ButtonComponent>
              <div>
                <ButtonComponent
                  onClick={(e: any) => {
                    e.stopPropagation()
                  }}
                >
                  <MoreVertical size={16} />
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      )}

      {moreDetails && (
        <div className={'more-details-style text-align-left'}>
          {/*Extra information*/}
          <Spacer height={10} />
          <span className={'more-details-data-style'}>
            <b className={'more-details-heading-style'}>Topic:</b>{' '}
            {topic ? topic : 'Local News'}
          </span>
          <span className={'more-details-data-style'}>
            <b className={'more-details-heading-style'}>Changed by:</b> {editor}
          </span>
          <span className={'more-details-data-style'}>
            <b className={'more-details-heading-style'}>Changed on:</b>{' '}
            {changedTime}
          </span>
          <span className={'more-details-data-style'}>
            <b className={'more-details-heading-style'}>Reason:</b> {reason}
          </span>
          <Spacer height={5} />
        </div>
      )}

      <div
        className={'mouse-pointer'}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '16px',
          marginTop: '0px',
        }}
        onClick={() => {
          setMoreDetails(!moreDetails)
        }}
      >
        {moreDetails ? (
          <>
            <span className={'more-style'}>Less</span>
            <img className={'action-img-trust-feed'} src={upDown} alt="" />
          </>
        ) : (
          <>
            <span className={'more-style'}>More</span>
            <img className={'action-img-trust-feed'} src={dropDown} alt="" />
          </>
        )}
      </div>
    </AnalyticsTrackerWrapper>
  )
}

export default TrustFeed

import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { notEmpty } from '../../../utils/formHelpers'
import { useSignupFormData } from '../signupFormData.store'
import { Input } from '../../../components-v2/shadcn/input'
import Button from '../../../components-v2/Button'
import Loading from '../../../components-v2/Loading'
import SelectUserTypeDialog from '../SelectUserTypeDialog'
import { Textarea } from '../../../components-v2/shadcn/textarea'
import { Camera } from 'lucide-react'
import ProfilePicUpload from '../../../components-v2/ProfilePicUpload'
import PublicHeader from '../../../components-v2/Header/PublicHeader'
import DateField from '../../../components-v2/EntryDialog/dialogs/AddQuote/DateField'
import moment from 'moment'
import { useRedirect } from '../useRedirect'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from '../../../components-v2/shadcn/dialog'
import LocationSelect from '../../../components-v2/LocationSelect/LocationSelect'
import { cn } from '../../../utils/cn'
import { useState } from 'react'
import { canLocationFind } from '../../../services/auth'

type Props = {}

const schema = z
  .object({
    firstName: z.string().pipe(notEmpty),
    profileImgSrc: z.string().optional(),
    lastName: z.string().pipe(notEmpty),
    homeTown: z.any().refine((v) => v, {
      message: 'Required',
    }),
    userType: z.string().pipe(notEmpty).optional(),
    about: z.string().optional(),
    phoneNumber: z.string().optional(),
    dob: z.date().optional(),
    verificationInfo: z.string().optional(),
  })
  .refine((v) => !(v.userType !== 'Opinion maker' && !v.phoneNumber), {
    path: ['phoneNumber'],
    message: 'Required',
  })
  .refine((v) => !(v.userType !== 'Opinion maker' && !v.dob), {
    path: ['dob'],
    message: 'Required',
  })
  .refine((v) => !(v.userType !== 'Opinion maker' && !v.verificationInfo), {
    path: ['verificationInfo'],
    message: 'Required',
  })

type SchemaType = z.infer<typeof schema>

export default function ProfileTab({}: Props) {
  useRedirect()

  const {
    register,
    handleSubmit,
    setError,
    reset,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {
      userType: 'Opinion maker',
    },
  })

  const [locationOpen, setLocationOpen] = useState(false)

  const userType = watch('userType')
  const profileImgSrc = watch('profileImgSrc')

  const setMultipleValues = useSignupFormData(
    (state: any) => state.setMultipleValues
  )

  const navigate = useNavigate()

  function renderError(key: string) {
    const err = (errors as any)?.[key]
    return (
      <>
        {err && (
          <p className="mt-1 text-xs font-bold text-red-500">{err?.message}</p>
        )}
      </>
    )
  }

  return (
    <div className="min-h-screen bg-primary font-inter text-white">
      <form
        className="mx-auto mb-20 mt-10 flex max-w-[300px] flex-col gap-5"
        onSubmit={handleSubmit(async (values) => {
          // console.log('🚀 ~ onSubmit={handleSubmit ~ values:', values)
          setMultipleValues(values)

          try {
            const isLocationFound = await canLocationFind(values?.homeTown)
            if (isLocationFound) {
              navigate('/signup/term')
            }
          } catch (e) {
            const error = e as { response?: any }
            setError('homeTown', {
              type: 'manual',
              message: error.response.data.errorMessage,
            })
          }
        })}
      >
        <h1 className="text-center font-medium">Set up your profile</h1>

        <div className="flex flex-col items-center">
          <ProfilePicUpload
            src={profileImgSrc}
            setSrc={(src: string) => {
              setValue('profileImgSrc', src)
            }}
          />
          {renderError('profileImgSrc')}
        </div>

        <div className="pt-[40px]">
          <p className="mb-[6px] text-xs font-bold">First name*</p>
          <Input
            className="h-[29px] border-2 border-line bg-transparent text-white placeholder:text-[11px] placeholder:italic placeholder:text-line"
            // placeholder="First name"
            {...register('firstName')}
          />
          {renderError('firstName')}
        </div>

        <div>
          <p className="mb-[6px] text-xs font-bold">Last name*</p>
          <Input
            className="h-[29px] border-2 border-line bg-transparent text-white placeholder:text-[11px] placeholder:italic placeholder:text-line"
            // placeholder="Last name"
            {...register('lastName')}
          />
          {renderError('lastName')}
        </div>

        <div>
          <p className="mb-[6px] text-xs font-bold">Hometown*</p>
          <Controller
            control={control}
            name="homeTown"
            render={({ field: { onChange, value } }) => {
              return (
                <Dialog open={locationOpen} onOpenChange={setLocationOpen}>
                  <DialogTrigger asChild>
                    <div>
                      <Input
                        autoComplete={'off'}
                        placeholder="Choose"
                        value={value?.display_name}
                        className="h-[29px] cursor-pointer border-2 border-line bg-transparent p-0 px-3 text-white placeholder:text-[11px] placeholder:italic placeholder:text-line"
                      />
                    </div>
                  </DialogTrigger>
                  <DialogContent className="w-[500px] p-0 mx-sm:w-screen">
                    <LocationSelect
                      onSelectLocation={(value) => {
                        // console.log('🚀 ~ onSelectLocation ~ value:', value)
                        // onChange(value)
                        // setLocationOpen(false)
                      }}
                      setLocationObj={(v: any) => {
                        // console.log(v)
                      }}
                      setLocation={(value: any) => {
                        console.log('🚀 ~ setLocation ~ value:', value)
                        onChange(value)
                        setLocationOpen(false)
                      }}
                    />
                  </DialogContent>
                </Dialog>
              )
            }}
          />
          {renderError('homeTown')}
        </div>

        <div>
          <p className="mb-[6px] text-xs font-bold">User Type*</p>
          <Controller
            control={control}
            name="userType"
            render={({ field: { onChange, value } }) => {
              return (
                <SelectUserTypeDialog
                  value={value}
                  trigger={
                    <Input
                      value={value}
                      className="h-[29px] cursor-pointer border-2 border-line bg-transparent p-0 pl-3 text-white placeholder:text-[11px] placeholder:italic placeholder:text-line"
                    />
                  }
                  onSelectItem={(type) => {
                    onChange(type)
                  }}
                />
              )
            }}
          />
          {renderError('userType')}
        </div>

        {userType !== 'Opinion maker' && (
          <fieldset className="flex flex-col gap-5 rounded-md bg-[#8D92AB] p-4 text-[#FBFBFD]">
            {/* <legend className="px-1 text-xs">
              For Verification: {userType}
            </legend> */}

            <p className="text-lg font-semibold text-primary">
              We need more information if you want to become a {userType}:
            </p>

            <div>
              <p className="mb-[6px] text-xs font-bold">Phone Number*</p>
              <Input
                className="h-[29px] border-2 border-line bg-transparent text-white placeholder:text-[11px] placeholder:italic placeholder:text-line"
                // placeholder="Phone Number"
                {...register('phoneNumber')}
              />
              {renderError('phoneNumber')}
            </div>

            <div>
              <p className="mb-[6px] text-xs font-bold">Date of birth*</p>
              <Controller
                control={control}
                name="dob"
                render={({ field: { onChange, value } }) => {
                  return (
                    <DateField
                      trigger={
                        <div>
                          <Input
                            placeholder="Choose"
                            value={
                              value
                                ? moment(value).format('YYYY-MM-DD')
                                : undefined
                            }
                            className="h-[29px] cursor-pointer border-2 border-line bg-transparent p-0 pl-3 text-white placeholder:text-[11px] placeholder:italic placeholder:text-line"
                          />
                        </div>
                      }
                      date={value}
                      setDate={onChange}
                    />
                  )
                }}
              />
              {renderError('dob')}
            </div>

            <div>
              <p className="mb-[6px] text-xs font-bold">Verification info*</p>
              <Textarea
                className="border-2 border-line bg-transparent text-white placeholder:text-[11px] placeholder:italic placeholder:text-line"
                placeholder="Share a social media handle, website, or other, where we can verify you."
                {...register('verificationInfo')}
              />
              {renderError('verificationInfo')}
            </div>
          </fieldset>
        )}

        <div>
          <p className="mb-[6px] text-xs font-bold">About yourself</p>
          <Textarea
            className="h-[29px] border-2 border-line bg-transparent text-white placeholder:text-[11px] placeholder:italic placeholder:text-line"
            placeholder="Optional - this text will be shown in your user profile"
            {...register('about')}
          />
          {renderError('about')}
        </div>

        <Button
          disabled={isSubmitting}
          type="submit"
          className="flex w-full items-center justify-center gap-2 text-black transition-all"
        >
          Continue {isSubmitting && <Loading />}
        </Button>
      </form>
    </div>
  )
}

import React, { useState } from 'react'
import { getHowLongAgo, getInitials } from './helpers'
import { cn } from '../../utils/cn'
import VerifiedIconSVG from '../../assets/icons/verified-blue.svg'
import PublicIconSVG from '../../assets/icons/public-yellow.svg'
import { Avatar, AvatarFallback } from '../shadcn/avatar'

type Props = {
  author: string
  organizationName?: string
  authorRole?: string
  organizationProfileUrl?: string
  authorProfileUrl?: string
  createdAt?: string
  blurProfile?: boolean
  organizationType?: string
  isVerified?: boolean
  isPublic?: boolean
  navigateToProfile?: () => void
  navigateToOrganisation?: () => void
  followed?: boolean
  onClickFollowUser?: (userId: any) => void
  isUsersPost?: boolean
  authorId?: string
}

export default function ProfileComponent({
  author,
  authorProfileUrl,
  authorRole,
  organizationName,
  organizationProfileUrl,
  createdAt,
  blurProfile = false,
  organizationType,
  isVerified,
  isPublic,
  navigateToProfile,
  navigateToOrganisation,
  followed,
  onClickFollowUser,
  isUsersPost,
  authorId,
}: Props) {
  const [isProfileBlured, setIsProfileBlured] = useState(blurProfile)

  const followUser = () => {
    if (onClickFollowUser) {
      onClickFollowUser(authorId)
    }
  }

  return (
    <div
      className={cn(
        'relative flex w-full items-center justify-between transition-all duration-500',
        {
          'blur-sm': isProfileBlured,
        }
      )}
    >
      {isProfileBlured && (
        <div
          className="absolute left-0 top-0 h-full w-full"
          onClick={(event) => {
            event.stopPropagation()
            setIsProfileBlured(false)
          }}
        />
      )}

      <div>
        <h3 className="flex items-center gap-1 text-xs font-semibold">
          <span
            onClick={(event) => {
              event.stopPropagation()
              if (navigateToProfile) {
                navigateToProfile()
              }
            }}
          >
            {author} ({getHowLongAgo(createdAt || '')}){' '}
          </span>
          <div className="mb-[01px] flex gap-[1px]">
            {isVerified && (
              <img
                src={VerifiedIconSVG}
                className="h-[15px] w-[15px]"
                title="Verified"
              />
            )}

            {isPublic && (
              <img
                src={PublicIconSVG}
                className="h-[15px] w-[15px]"
                title="Public"
              />
            )}
            {!isUsersPost &&
              followed !== undefined &&
              (followed ? (
                <span
                  className={'ml-2 text-gray-400'}
                  onClick={(event: any) => {
                    event.stopPropagation()
                    followUser()
                  }}
                >
                  ✓ Following
                </span>
              ) : (
                <span
                  className={'ml-2 text-blue-700'}
                  onClick={(event: any) => {
                    event.stopPropagation()
                    followUser()
                  }}
                >
                  + Follow
                </span>
              ))}
            {/*+✓ Following*/}
          </div>
        </h3>
        <h4 className="flex gap-1">
          {organizationName && (
            <span
              onClick={(event) => {
                event.stopPropagation()
                if (navigateToOrganisation) {
                  navigateToOrganisation()
                }
              }}
              className="text-xs font-semibold"
            >
              {organizationName}
            </span>
          )}

          {authorRole && (
            <span
              className="text-xs text-subtext"
              onClick={(event) => {
                event.stopPropagation()
                if (navigateToProfile) {
                  navigateToProfile()
                }
              }}
            >
              {authorRole}
            </span>
          )}
        </h4>
        <h4 className={'flex'}>
          {organizationType && (
            <span
              className="text-xs text-subtext"
              onClick={(event) => {
                event.stopPropagation()
                if (navigateToOrganisation) {
                  navigateToOrganisation()
                }
              }}
            >
              {organizationType}
            </span>
          )}
        </h4>
      </div>

      <div className="flex items-center">
        {organizationName && organizationProfileUrl && (
          <img
            onClick={(event) => {
              event.stopPropagation()
              if (navigateToOrganisation) {
                navigateToOrganisation()
              }
            }}
            src={organizationProfileUrl}
            className="mr-1 h-[33px] w-[33px] rounded-full object-cover"
          />
        )}
        {authorProfileUrl ? (
          <img
            onClick={(event) => {
              event.stopPropagation()
              if (navigateToProfile) {
                navigateToProfile()
              }
            }}
            src={authorProfileUrl}
            className="mr-2 h-[34px] w-[34px] rounded-full object-cover"
          />
        ) : (
          <span className="mr-2 h-[34px] w-[34px] rounded-full object-cover"></span>
        )}
      </div>
    </div>
  )
}

import React from 'react'
import { getHowLongAgo } from './helpers'
import VerifiedIconSVG from '../../assets/icons/verified-blue.svg'
import PublicIconSVG from '../../assets/icons/public-yellow.svg'

type Props = {
  userName: string
  userRole?: string
  userProfileImg?: string
  customUserName?: string
  createdAt?: string
  isVerified?: boolean
  isPublic?: boolean
  isFollowed?: boolean
  isUsersQuotes?: boolean
  onClickFollowUser?: (userId: any) => void
  authorId?: string
}

export default function QuoteProfileComponent({
  userName,
  userProfileImg,
  userRole,
  customUserName,
  createdAt,
  isVerified,
  isPublic,
  isFollowed,
  isUsersQuotes,
  onClickFollowUser,
  authorId,
}: Props) {
  const followUser = () => {
    if (onClickFollowUser) {
      onClickFollowUser(authorId)
    }
  }
  return (
    <div className="flex justify-between w-full items-center">
      <div>
        <h3 className="text-xs font-semibold flex items-center gap-1">
          <span>
            {customUserName || userName} ({getHowLongAgo(createdAt || '')})
          </span>

          <div className="flex gap-[1px] mb-[01px]">
            {isVerified && (
              <img
                src={VerifiedIconSVG}
                className="w-[15px] h-[15px] "
                title="Verified"
              />
            )}

            {isPublic && (
              <img
                src={PublicIconSVG}
                className="w-[15px] h-[15px] "
                title="Public"
              />
            )}
            {!isUsersQuotes &&
              authorId &&
              (isFollowed ? (
                <span
                  className={'ml-2 text-gray-400'}
                  onClick={(event: any) => {
                    event.stopPropagation()
                    followUser()
                  }}
                >
                  ✓ Following
                </span>
              ) : (
                <span
                  className={'ml-2 text-blue-700'}
                  onClick={(event: any) => {
                    event.stopPropagation()
                    followUser()
                  }}
                >
                  + Follow
                </span>
              ))}
          </div>
        </h3>
        <h4 className="flex gap-1">
          {userRole && (
            <span className="text-xs text-subtext">
              {userRole || 'Houseguest'}
            </span>
          )}
        </h4>
      </div>

      <div className="flex items-center">
        {userProfileImg && (
          <img
            src={
              userProfileImg ||
              'https://static.vecteezy.com/system/resources/previews/020/765/399/non_2x/default-profile-account-unknown-icon-black-silhouette-free-vector.jpg'
            }
            className="w-[34px] h-[34px] object-cover rounded-full mr-1"
          />
        )}
      </div>
    </div>
  )
}

import Spacer from '../../components/Spacer'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import './index.css'
import { connect } from 'react-redux'
import TrustFeed from '../../components/TrustFeed'
import { addPostsToRedux } from '../../redux/analytics/actions'
import {
  trustFeedContrasts,
  getTrustFeedChanges,
  trustFeedSeen,
} from '../../redux/trustFeed/action'
import {
  alarmTogglePost,
  alarmToggleTrustFeedChanges,
  alarmToggleTrustFeedContrasts,
} from '../../redux/posts/actions'
import { useNavigate } from 'react-router-dom'
import AnalyticsTrackerWrapper from '../../containers/AnalyticsTrackerWrapper'
import Tabs from '../../components-v2/Tabs'
import TrustFeedContrasts from './TrustFeedContrasts'
import PostCard from '../../components-v2/Post/PostCard'
import { parsePostObjToProps } from '../../components-v2/Post/helpers'
import ContrastSVG from '../../assets/icons/contrast-lighter.svg'

import { alarmToggle, upvoteToggle } from '../../components-v2/Post/services'
import userEvent from '@testing-library/user-event'

const TrustFeedScreen = ({
  getTrustFeedChanges,
  trustFeedChanges,
  userId,
  trustFeedContrasts,
  trustFeedContrastsPosts,
  alarmTogglePost,
  alarmToggleTrustFeedChanges,
  alarmToggleTrustFeedContrasts,
  trustFeedSeen,
  isTrustFeedAutoLoad,
  isTrustFeedSeen,
  addPostsToRedux,
}: any) => {
  const navigate = useNavigate()
  const [currentTab, setCurrentTab] = useState(
    trustFeedChanges?.length > 0
      ? 'changes'
      : trustFeedContrastsPosts?.length > 0
        ? 'contrasts'
        : 'changes'
  )

  useEffect(() => {
    if (isTrustFeedSeen) {
      return
    } else {
    }
  }, [isTrustFeedSeen])

  const location = useLocation()

  useEffect(() => {
    const unlisten = () => {
      yourFunction()
    }

    return () => {
      unlisten()
    }
  }, [location]) // Re-run effect when location changes

  const yourFunction = () => {
    trustFeedSeen()
  }

  useEffect(() => {
    console.log('trustFeedChanges::::::', trustFeedChanges)
    if (currentTab === 'changes') {
      getTrustFeedChanges()
    } else {
      trustFeedContrasts()
    }
  }, [currentTab, getTrustFeedChanges, trustFeedContrasts])

  return (
    <AnalyticsTrackerWrapper>
      <div>
        <div className="h-11 mb-2">
          <Tabs
            className="h-11 mx-mobile:h-fit mx-mobile:py-2 items-center fixed border-b bg-white z-30"
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            tabs={[
              {
                value: 'changes',
                label: 'Changes',
              },
              {
                value: 'contrasts',
                label: 'Contrasts',
              },
            ]}
          />
        </div>
        {currentTab === 'changes' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              overflowY: 'auto',
            }}
          >
            {trustFeedChanges?.length > 0 ? (
              trustFeedChanges?.map((trustFeed: any) => {
                return (
                  <TrustFeed
                    topicId={trustFeed?.editedPost?.topicId}
                    postId={trustFeed?.post?.id}
                    post={trustFeed?.post}
                    editor={trustFeed?.editedUser}
                    editedPostId={trustFeed?.editedPost?.id}
                    alarmToggleTrustFeedChanges={alarmToggleTrustFeedChanges}
                    editedPost={trustFeed?.editedPost}
                    key={trustFeed?.editedPost?.id}
                    visual={trustFeed?.visual}
                    sentence={trustFeed?.sentence}
                    editedPostType={trustFeed?.editedPost?.postTypeId}
                    editedPostContent={trustFeed?.editedPost?.description}
                    userName={trustFeed?.post?.author?.name}
                    userProfession={trustFeed?.post?.author?.roles?.name}
                    postType={trustFeed?.post?.postTypeId}
                    postContent={trustFeed?.post?.description}
                    isDeleted={trustFeed?.isDeleted}
                    isEditContent={trustFeed?.isTextEdit}
                    isEditType={trustFeed?.isTypeEdit}
                    changedTime={trustFeed?.editedPost?.createdAt}
                    reason={trustFeed?.post?.activities[0]?.reason}
                    changedBy={trustFeed?.editedPost?.author?.name}
                    editedPostMedia={trustFeed?.editedPost?.mediaId}
                    postMedia={trustFeed?.post?.mediaId}
                    topic={trustFeed?.post?.topic?.title}
                    vote={trustFeed?.post?.vote}
                    userId={String(userId)}
                    userProfile={trustFeed?.post?.author?.imageUrl}
                    addPostsToRedux={addPostsToRedux}
                  />
                )
              })
            ) : (
              <div className="text-center text-xs text-border mt-10">
                You're all caught up
              </div>
            )}
          </div>
        ) : (
          <>
            {trustFeedContrastsPosts?.length > 0 ? (
              <TrustFeedContrasts
                trustFeedContrasts={trustFeedContrastsPosts}
                addPostsToRedux={addPostsToRedux}
              />
            ) : (
              <div className="text-center text-xs text-border mt-12">
                You're all caught up
              </div>
            )}
          </>
        )}
        <Spacer height={40} />
        {!isTrustFeedSeen &&
          (trustFeedChanges?.length > 0 ||
            trustFeedContrastsPosts?.length > 0) && (
            <div
              className={'bottom-nav-bar bottom-nav-bar-text'}
              onClick={() => {
                navigate('/')
                trustFeedSeen()
              }}
            >
              Skip & go to home feed
            </div>
          )}
      </div>
    </AnalyticsTrackerWrapper>
  )
}

const mapStateToProps = (state: any) => {
  return {
    trustFeedChanges: state.trustFeed.trustFeedChanges,
    userId: state?.login?.user?.id,

    //trust feed contrasts
    trustFeedContrastsPosts: state.trustFeed.trustFeedContrasts,
    isTrustFeedAutoLoad: state.trustFeed.isTrustFeedAutoLoad,
    isTrustFeedSeen: state.trustFeed.isTrustFeedSeen,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    alarmToggleTrustFeedChanges: (postId: any) => {
      dispatch(alarmToggleTrustFeedChanges(postId))
    },
    getTrustFeedChanges: () => {
      dispatch(getTrustFeedChanges())
    },
    trustFeedContrasts: () => {
      dispatch(trustFeedContrasts())
    },
    alarmTogglePost: (postId: any) => {
      dispatch(alarmTogglePost(postId))
    },
    alarmToggleTrustFeedContrasts: (postId: any) => {
      dispatch(alarmToggleTrustFeedContrasts(postId))
    },
    trustFeedSeen: () => {
      dispatch(trustFeedSeen())
    },
    addPostsToRedux: (post: any) => {
      dispatch(addPostsToRedux(post))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrustFeedScreen)

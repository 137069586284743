import React, { useCallback, useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTrigger } from '../../../shadcn/dialog'
import { Input } from '../../../shadcn/input'
import Button from '../../../Button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../../shadcn/popover'
import PartyCard from './PartyCard'
import debounce from '../../../../utils/debounce'
import { searchUsers } from '../../../../services/user'
import Loading from '../../../Loading'

type Props = {
  open: boolean
  setOpen: (isOpen: boolean) => void
  trigger?: React.ReactNode
  onSelectClick?: (userId: string) => void
  onlyVerified?: boolean
  title: string
  forLocationPurpose?: boolean
}

export default function UserSearchDialog({ ...rest }: Props) {
  const { open, setOpen, trigger } = rest
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent className="max-h-screen max-w-[450px] overflow-y-auto">
        <Content {...rest} />
      </DialogContent>
    </Dialog>
  )
}

function Content({
  open,
  setOpen,
  onSelectClick,
  onlyVerified,
  title,
  forLocationPurpose = false,
}: Props) {
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  // console.log('🚀 ~ Content ~ users:', users)

  // const debouncedOnChange = useCallback((value: string) => {
  //   searchUsers(value, onlyVerified)
  //     .then((data) => {
  //       setUsers(data)
  //     })
  //     .finally(() => {
  //       setLoading(false)
  //     })
  // }, [])

  const debouncedOnChange = useCallback(
    debounce((value) => {
      // console.log('Input value:', value)
      if (value) {
        setLoading(true)
        searchUsers({
          text: value,
          onlyVerified: onlyVerified,
        })
          .then((data) => {
            setUsers(data)
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        setUsers([])
      }
    }, 300),
    []
  )

  // useEffect(() => {
  //   console.log('USe effect')
  // }, [])

  return (
    <div>
      <p className="text-base font-bold">{title}</p>_{' '}
      <p className="text-xs mt-3">You can only search for verified users.</p>
      <Input
        className="mt-1 h-[29px] w-full border-line text-xs placeholder:text-[11px] placeholder:italic placeholder:text-line"
        placeholder="Name"
        onChange={(event) => {
          debouncedOnChange(event.target.value)
        }}
      />
      {loading && (
        <div className="mt-2 flex justify-center">
          <Loading />
        </div>
      )}
      <div className="mt-5 flex flex-col gap-[19px]">
        {users?.map((x: any) => {
          return (
            <PartyCard
              name={x.name}
              roleName={x.roles?.name}
              profileImageUrl={x?.imageUrl}
              location={x?.userLocations?.name}
              organization={x?.organizationUsers?.[0]?.name}
              onClickCard={() => {
                onSelectClick && onSelectClick(x?.id)
              }}
              isVerified={x?.verified}
              isPublic={x?.public}
              forLocationPurpose={forLocationPurpose}
            />
          )
        })}
      </div>
      <Button
        className="mt-5 w-full"
        onClick={() => {
          setOpen(false)
        }}
      >
        Cancel
      </Button>
    </div>
  )
}

import { useEffect, useState } from 'react'
import { Dialog, DialogContent } from './shadcn/dialog'

type DialogProps = {
  open: boolean
  setOpen: (isOpen: boolean) => void
  url?: string
  defaultOpen?: boolean
}

export default function NoAuthDialog(props: DialogProps) {
  const { open, setOpen, defaultOpen } = props

  useEffect(() => {
    if (defaultOpen) {
      setOpen(true)
    }
  }, [])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="p-0 border-0 bg-primary max-w-[800px] h-[80vh] [&>button]:text-white">
        <Content {...props} />
      </DialogContent>
    </Dialog>
  )
}

function Content({ url, defaultOpen, setOpen }: DialogProps) {
  return (
    <>
      <iframe
        src={url}
        // width="600"
        // height="400"
        className="w-full h-full"
      />
    </>
  )
}

import { z } from 'zod'
import { notEmpty } from '../../../../utils/formHelpers'

const postSchema = z.object({
  text: z.string().max(300).pipe(notEmpty),
  imageUrl: z.string().optional(),
  postType: z.string().pipe(notEmpty),
})

const quoteSchema = z
  .object({
    userId: z.string().optional(),
    customUserName: z.string().optional(),
    text: z.string().max(300).pipe(notEmpty),
    role: z.string().optional(),
    source: z.string().pipe(notEmpty),
    date: z.date(),
    imageUrl: z.string().optional(),
  })
  .superRefine((values, ctx) => {
    // console.log('super refine rinnging', quoteSchema.safeParse(post))
    console.log(values.userId, values.customUserName)

    if (!values.customUserName && !values.userId) {
      console.log('No user')

      ctx.addIssue({
        path: ['userId'],
        message: 'User required',
        code: 'custom',
      })
    }

    // else {
    //   console.log('user exists')
    //   ctx.addIssue({
    //     path: ['user'],
    //     message: 'User exists',
    //     code: 'custom',
    //   })
    // }
  })

export const schema = z.object({
  topicId: z.string().pipe(notEmpty),
  organizationId: z.string().optional(),
  url: z.string().url(),
  posts: z
    .array(
      z
        .object({
          type: z.union([z.literal('post'), z.literal('quote')]),
          // data: z.union([postSchema, quoteSchema]).optional(),
          data: z.any(),
        })
        // .partial()
        .superRefine((post, ctx) => {
          // console.log('super refine rinnging', quoteSchema.safeParse(post))

          if (post.type === 'quote') {
            const quoteParse = quoteSchema.safeParse(post.data)
            // console.log('🚀 ~ .superRefine ~ quoteParse:', quoteParse)
            if (!quoteParse.success) {
              for (const issue of quoteParse.error.issues) {
                ctx.addIssue(issue)
              }
            }
          }

          if (post.type === 'post') {
            const postParse = postSchema.safeParse(post.data)
            if (!postParse.success) {
              for (const issue of postParse.error.issues) {
                ctx.addIssue(issue)
              }
            }
          }
        })
    )
    .min(2)
    .max(5),
})

export type SchemaType = z.infer<typeof schema>
export type PostSchemaType = z.infer<typeof postSchema>
export type QuoteSchemaType = z.infer<typeof quoteSchema>

import { XIcon } from 'lucide-react'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import ArrowLeftSVG from '../../assets/icons/arrow-small-left.svg'
import FilterWheelEntry from '../../components-v2/FilterWheel3/FilterWheelEntry'
import {
  orderFilterOptions,
  timeFilterOptions,
} from '../../components-v2/FilterWheel3/helpers'
import Tabs from '../../components-v2/Tabs'
import { addPostsToRedux } from '../../redux/analytics/actions'
import {
  deleteArticlesForTopic,
  getTopicArticles,
} from '../../redux/article/actions'
import {
  alarmToggleTopic,
  deletePost,
  getAllPosts,
  getAllPostsForTopics,
  voteToggleTopic,
} from '../../redux/posts/actions'

import {
  deleteTopic,
  getTopic,
  setClickedTopic,
} from '../../redux/topics/actions'
import {
  getAllArticlesOfATopic,
  getArticleForOrganization,
} from '../../services/article'
import { getPostsOfALocalTopic, getPostsOfATopic } from '../../services/post'
import DescriptionCard from '../ArticleScreen/DescriptionCard'
import {
  filterPosts,
  getDisabledStates,
  getInvolvedDisableState,
  getOrderFilteredPosts,
  getTimeFilteredPosts,
} from './helper'

import ArticlesTab from './tabs/ArticlesTab'
import PostsTab from './tabs/PostsTab'
import { isAuthenticated } from '../../utils/auth'
import Header from '../../components-v2/Header/Header'
import { getMe } from '../../redux/login/actions'
import { useOrganizationsStore } from './organization.store'
import { getAllPostsForUser } from '../../services/post'
import { getUserDetails } from '../../services/user'
import { getAllPostsForOrg, getOrganization } from '../../services/organization'
import { cn } from '../../utils/cn'
import TopicFollowButton from '../../components-v2/TopicFollowButton'
import DescriptionCardForScreens from '../UserScreen-v2/DescriptionCardForScreens'
import { getTopicForOrganization, getTopicForUser } from '../../services/topic'
import UserScreenTopics from '../../components/UserScreenTopics'
import whiteLens from '../../assets/icons/lens.svg'

const OrganizationScreen = ({
  getTopic,
  topic,
  setClickedTopic,

  setPostModalVisible,
  setSelectTopicPostCreateModalVisible,
  setContrastsModalVisible,
  topicPosts,
  getAllPostsForTopics,
  getTopicArticles,
  deletePost,
  deleteTopic,

  topicArticles = [],
  voteToggleTopic,
  deleteArticlesForTopic,
  setParamsForArticleModal,
  pageArticle,
  setPageArticle,
  addPostsToRedux,
  alarmToggleTopic,
  permissions,
  getMe,
}: any) => {
  const [currentTab, setCurrentTab] = useState('posts')
  const params = useParams()
  const { orgId } = params

  const [loadingPosts, setLoadingPosts] = useState(false)
  // const [posts, setPosts] = useState<any[]>([])
  const posts = useOrganizationsStore((state: any) => state.posts)
  const setPosts = useOrganizationsStore((state: any) => state.setPosts)
  const [allPosts, setAllPosts] = useState(
    useOrganizationsStore((state: any) => state.posts)
  )
  console.log('🚀 ~ allPosts:', allPosts)
  // console.log(
  //   '🚀 ~ posts:',
  //   posts.filter((x) => !!x.organization)
  // )

  const [loadingArticles, setLoadingArticles] = useState(false)
  // const [articles, setArticles] = useState<any[]>([])
  const articles = useOrganizationsStore((state: any) => state.articles)
  const setArticles = useOrganizationsStore((state: any) => state.setArticles)

  const organizationDetails = useOrganizationsStore(
    (state: any) => state.organizationDetails
  )
  const setOrganizationDetails = useOrganizationsStore(
    (state: any) => state.setOrganizationDetails
  )

  const organizationTopics = useOrganizationsStore((state: any) => state.topics)
  const setOrganizationTopics = useOrganizationsStore(
    (state: any) => state.setTopics
  )

  const [filterPostType, setFilterPostType] = useState('all')
  const [filterOrganization, setFilterOrganization] = useState('all')

  const defaultFilterTime = 'all'
  const [filterTime, setFilterTime] = useState(defaultFilterTime)
  const defaultFilterOrder = 'new-to-old'
  const [filterOrder, setFilterOrder] = useState(defaultFilterOrder)

  const [postTypeWheelOpen, setPostTypeWheelOpen] = useState(false)
  const [orgWheelOpen, setOrgWheelOpen] = useState(false)
  const [userName, setUserName] = useState('')

  const isAuthed = isAuthenticated()

  const loadPosts = useCallback(() => {
    if (orgId) {
      // getAllPostsForOrg(true)

      setLoadingPosts(true)
      if (orgId) {
        getAllPostsForOrg(orgId)
          .then((data: any) => {
            setPosts(data)
          })
          .finally(() => {
            setLoadingPosts(false)
          })
      }
    }
  }, [orgId])

  const loadArticles = useCallback(() => {
    if (orgId) {
      setLoadingArticles(true)
      getArticleForOrganization(orgId)
        .then((data) => {
          setArticles(data)
        })
        .finally(() => {
          setLoadingArticles(false)
        })
    }
  }, [orgId])

  useEffect(() => {
    getOrganization(orgId)
      .then((data) => {
        setOrganizationDetails(data)
      })
      .finally(() => {})

    getTopicForOrganization(orgId)
      .then((data) => {
        setOrganizationTopics(data)
      })
      .finally(() => {})
  }, [orgId])

  useEffect(() => {
    if (currentTab === 'posts') {
      loadPosts()
    }

    if (currentTab === 'articles') {
      loadArticles()
    }
  }, [currentTab, loadPosts, loadArticles])

  // TODO: memoize this for performance
  const disabledItems = getDisabledStates(posts, topic?.associatedUsers)

  let tab = null
  switch (currentTab) {
    case 'posts':
      const filteredLayer1 = filterPosts(
        posts,
        filterPostType,
        filterOrganization,
        topic?.associatedUsers
      )
      const filteredLayer2 = getTimeFilteredPosts(filteredLayer1, filterTime)
      const filteredLayer3 = getOrderFilteredPosts(filteredLayer2, filterOrder)

      tab = (
        <PostsTab
          posts={filteredLayer3}
          cb={loadPosts}
          isLoading={loadingPosts && posts.length <= 0}
        />
      )
      break

    case 'articles':
      tab = (
        <ArticlesTab
          articles={articles}
          cb={loadArticles}
          isLoading={loadingArticles && articles.length <= 0}
        />
      )
      break
  }

  useEffect(() => {
    getMe()
  }, [getMe])

  const [previousTopicId, setPreviousTopicId] = useState('')
  const filterPostsBasedOnTopic = (topicId: string) => {
    getAllPostsForOrg(orgId)
      .then((data: any) => {
        if (topicId === previousTopicId) {
          setPosts(data)
          setPreviousTopicId('')
          return
        }
        const filterPosts = data.filter(
          (post: any) => post?.topicId === topicId
        )
        setPreviousTopicId(topicId)
        setPosts(filterPosts)
      })
      .finally(() => {
        setLoadingPosts(false)
      })
  }

  const navigate = useNavigate()

  const [selectedTopic, setSelectedTopic] = useState(null)

  const handleTopicClick = (topicId: any) => {
    if (selectedTopic === topicId) {
      setSelectedTopic(null) // Deselect if the same topic is clicked again
    } else {
      setSelectedTopic(topicId) // Select the new topic
    }
    filterPostsBasedOnTopic(topicId)
  }

  return (
    <>
      <div className={'mb-8'}>
        <div className="h-[38px]">
          <div className="fixed z-30 h-[38px] w-full justify-between border-b bg-white">
            <div className="mx-auto flex max-w-[390px] items-center">
              <img
                src={ArrowLeftSVG}
                className="h-6 w-6 cursor-pointer rounded-lg transition-all hover:bg-line"
                onClick={() => {
                  navigate(-1)
                }}
              />
              <Tabs
                className="items-center py-2"
                setCurrentTab={setCurrentTab}
                currentTab={currentTab}
                tabs={[
                  {
                    value: 'posts',
                    label: 'Posts',
                  },
                  {
                    value: 'articles',
                    label: 'Articles',
                  },
                ]}
              />

              <div className="h-6 w-6" />
            </div>
          </div>
        </div>

        <div className="mx-auto hidden max-w-[390px] mx-desktop:block">
          <DescriptionCardForScreens
            name={organizationDetails?.name}
            // location={userDetails?.userLocations?.address}
            description={organizationDetails?.description}
            role={organizationDetails?.type}
            profile={organizationDetails?.imageUrl}
            createdAt={organizationDetails?.createdAt}
            profileLink={organizationDetails?.orgLink}
            type={'organization'}
          />
        </div>

        <div className="mx-auto flex w-full max-w-[2000px] mx-desktop:flex-col">
          {/* Left div */}
          <div
            className="relative min-h-44 mx-desktop:hidden"
            style={{
              flex: '0 0 30%',
            }}
          >
            <div className="fixed w-[30%] max-w-[600px]">
              <DescriptionCardForScreens
                name={organizationDetails?.name}
                // location={userDetails?.userLocations?.address}
                description={organizationDetails?.description}
                role={organizationDetails?.type}
                profile={organizationDetails?.imageUrl}
                createdAt={organizationDetails?.createdAt}
                profileLink={organizationDetails?.orgLink}
                type={'organization'}
              />

              {/* <PostTypeWheel /> */}
              {currentTab === 'posts' && (
                <FilterWheelEntry
                  className="mt-10"
                  currentPostType={filterPostType}
                  setPostType={(type: string) => {
                    setFilterOrganization('all')
                    setFilterPostType(type)
                  }}
                  currentOrgType={filterOrganization}
                  setOrgType={(org: string) => {
                    setFilterPostType('all')
                    setFilterOrganization(org)
                  }}
                  setFilterOrder={setFilterOrder}
                  filterOrder={filterOrder}
                  setFilterTime={setFilterTime}
                  filterTime={filterTime}
                  postTypeWheelOpen={postTypeWheelOpen}
                  setPostTypeWheelOpen={setPostTypeWheelOpen}
                  orgWheelOpen={orgWheelOpen}
                  setOrgWheelOpen={setOrgWheelOpen}
                  disabledPostTypes={disabledItems.postTypes}
                  disabledOrgTypes={disabledItems.orgTypes}
                />
              )}
            </div>
          </div>

          {/* Middle div */}
          <div
            className="flex min-h-screen flex-col items-center border-r border-line mx-desktop:border-r-0 mx-mobile:px-5"
            style={{
              flex: '0 0 40%',
            }}
          >
            {/* Filters */}
            {currentTab === 'posts' && (
              <div className="mb-4 mt-5 flex flex-wrap items-center gap-1 mobile:px-5">
                {filterPostType !== 'all' && (
                  <div
                    className="flex cursor-pointer items-center rounded-md bg-[#d5e7c4] px-2 py-1 text-sm text-white"
                    onClick={() => {
                      setPostTypeWheelOpen(true)
                    }}
                  >
                    Filtering {filterPostType}
                    <XIcon
                      className="-mr-[4px] mb-[1px] flex-1 cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilterPostType('all')
                      }}
                    />
                  </div>
                )}

                {filterOrganization !== 'all' && (
                  <div
                    className="flex cursor-pointer items-center rounded-md bg-[#d5e7c4] px-2 py-1 text-sm text-white"
                    onClick={() => {
                      setOrgWheelOpen(true)
                    }}
                  >
                    Filtering {filterOrganization}
                    <XIcon
                      className="-mr-[4px] mb-[1px] flex-1 cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilterOrganization('all')
                      }}
                    />
                  </div>
                )}

                {filterTime && filterTime !== 'all' && (
                  <div
                    className="flex cursor-pointer items-center gap-1 rounded-md bg-[#d5e7c4] px-2 py-1 text-sm text-white"
                    onClick={() => {
                      setPostTypeWheelOpen(true)
                    }}
                  >
                    {
                      timeFilterOptions.find((x) => x.value === filterTime)
                        ?.label
                    }
                    <XIcon
                      className="-mr-[4px] mb-[1px] flex-1 cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilterTime(defaultFilterTime)
                      }}
                    />
                  </div>
                )}

                {filterOrder && filterOrder !== 'new-to-old' && (
                  <div
                    className="flex cursor-pointer items-center gap-1 rounded-md bg-[#d5e7c4] px-2 py-1 text-sm text-white"
                    onClick={() => {
                      setPostTypeWheelOpen(true)
                    }}
                  >
                    {
                      orderFilterOptions.find((x) => x.value === filterOrder)
                        ?.label
                    }

                    <XIcon
                      className="-mr-[4px] mb-[1px] flex-1 cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilterOrder(defaultFilterOrder)
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {tab}
          </div>
          {/* Right div */}
          {/*<div*/}
          {/*  className="min-h-44 relative mx-desktop:hidden flex-shrink-0 w-1/3 overflow-hidden"*/}
          {/*  style={{*/}
          {/*    flex: '0 0 30%',*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <div className={'flex flex-col items-center justify-center'}>*/}
          {/*    <text className="text-gray-500 font-normal text-2xl">*/}
          {/*      Filter topics*/}
          {/*    </text>*/}
          {/*  </div>*/}
          {/*  {organizationTopics?.map((topic: any) => (*/}
          {/*    <UserScreenTopics*/}
          {/*      onClick={() => {*/}
          {/*        filterPostsBasedOnTopic(topic?.id)*/}
          {/*      }}*/}
          {/*      topicName={topic.title}*/}
          {/*    />*/}
          {/*  ))}*/}
          {/*</div>*/}
          <div
            className="relative min-h-44 w-2/3 flex-shrink-0 overflow-hidden mx-desktop:hidden"
            style={{
              flex: '0 0 30%',
            }}
          >
            <div
              className={'mb-4 mt-6 flex flex-row items-center justify-center'}
            >
              <text
                className="font-weight-600 text-[14px] font-normal text-[#9D9D9D]"
                style={{ fontFamily: 'Inter' }}
              >
                Filter topics
              </text>
              {/*<img*/}
              {/*  onClick={(event) => {*/}
              {/*    event.stopPropagation()*/}
              {/*    // if (navigateToProfile) {*/}
              {/*    //   navigateToProfile()*/}
              {/*    // }*/}
              {/*  }}*/}
              {/*  src={whiteLens}*/}
              {/*  className="ml-1 h-[16px] w-[16px] object-cover"*/}
              {/*/>*/}
            </div>

            {organizationTopics?.map((topic: any) => (
              <UserScreenTopics
                key={topic?.id}
                topicKey={topic?.id}
                onClick={() => {
                  handleTopicClick(topic?.id)
                }}
                topicName={topic.title}
                // place={topic.locationId}
                selected={selectedTopic === topic.id}
              />
            ))}
          </div>
        </div>

        {currentTab === 'posts' && (
          <div
            className="h- fixed bottom-3 right-3 z-50 rounded-full bg-white desktop:hidden"
            style={{
              // '-webkit-box-shadow': '0px 0px 49px 21px rgba(166,166,166,1)',
              // '-moz-box-shadow': '0px 0px 49px 21px rgba(166,166,166,1)',
              boxShadow: 'rgb(255, 255, 255) 0px 0px 15px 8px',
            }}
          >
            <FilterWheelEntry
              currentPostType={filterPostType}
              setPostType={(type: string) => {
                setFilterOrganization('all')
                setFilterPostType(type)
              }}
              currentOrgType={filterOrganization}
              setOrgType={(org: string) => {
                setFilterPostType('all')
                setFilterOrganization(org)
              }}
              setFilterOrder={setFilterOrder}
              filterOrder={filterOrder}
              setFilterTime={setFilterTime}
              filterTime={filterTime}
              postTypeWheelOpen={postTypeWheelOpen}
              setPostTypeWheelOpen={setPostTypeWheelOpen}
              orgWheelOpen={orgWheelOpen}
              setOrgWheelOpen={setOrgWheelOpen}
              disabledPostTypes={disabledItems.postTypes}
              disabledOrgTypes={disabledItems.orgTypes}
            />
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    posts: state.posts.posts,
    topicPosts: state.posts.topicPosts,
    postsFailed: state.posts.postsFailed,
    permissions: state?.login?.user?.roles,

    topic: state.topics.topic,

    topicArticles: state.articles.topicArticles,
    topicsID: state.topics.topicsID,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllPosts: () => {
      dispatch(getAllPosts())
    },
    getAllPostsForTopics: (params: any) => {
      dispatch(getAllPostsForTopics(params))
    },
    getTopic: (id: string) => {
      dispatch(getTopic(id))
    },
    getAllPostsForUser: (postsForUser: any) => {
      dispatch(getAllPostsForUser(postsForUser))
    },
    getUserDetails: (userId: any) => {
      dispatch(getUserDetails(userId))
    },
    getMe: () => {
      dispatch(getMe())
    },
    getTopicArticles: (topicId: string) => {
      dispatch(getTopicArticles(topicId))
    },
    setClickedTopic: (topic: any) => {
      dispatch(setClickedTopic(topic))
    },
    deletePost: (post: any, isVisible: any, reason: any) => {
      dispatch(deletePost(post, isVisible, reason))
    },
    deleteTopic: (topic: any) => {
      dispatch(deleteTopic(topic))
    },
    voteToggleTopic: (postId: any, topicId: any) => {
      dispatch(voteToggleTopic(postId, topicId))
    },
    alarmToggleTopic: (postId: any, topicId: any) => {
      dispatch(alarmToggleTopic(postId, topicId))
    },
    deleteArticlesForTopic: (artcileId: any, topicId: any) => {
      dispatch(deleteArticlesForTopic(artcileId, topicId))
    },
    addPostsToRedux: (postId: any) => {
      dispatch(addPostsToRedux(postId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationScreen)

import { useEffect, useState } from 'react'
import { getAllTopics } from '../../redux/topics/actions'
import { selectPostDetails, postEditRules } from '../../redux/posts/actions'
import { connect, useDispatch } from 'react-redux'
import { getMe } from '../../redux/login/actions'
import { useNavigate } from 'react-router-dom'
import { addPostsToRedux } from '../../redux/analytics/actions'
import AnalyticsTrackerWrapper from '../../containers/AnalyticsTrackerWrapper'
import PostWithContrast from '../../components-v2/Post/PostWithContrast'
import AddSVG from '../../assets/icons/add.svg'
// import EntryDialog from '../../containers/PostScreen/EntryDialog'
import EntryDialog from '../../components-v2/EntryDialog/EntryDialog'
import { ChevronRight } from 'lucide-react'
import {
  getTrustFeedChanges,
  trustFeedContrasts,
} from '../../redux/trustFeed/action'
import Header from '../../components-v2/Header/Header'
import { isAuthenticated } from '../../utils/auth'
import SidebarContent from '../../components-v2/Header/Sidebar/SidebarContent'
import { followATopic } from '../../services/topic'
import TopicFollowButton from '../../components-v2/TopicFollowButton'
import { locationToDisplay } from '../../components-v2/Post/helpers'
import FilterWheelEntry from './FilterWheelEntry'
import { getNextPageParam, getNiooze, getNioozeWrapper } from './helper'
import { useHome } from './home.store'
import { useInView } from 'react-intersection-observer'
import Loading from '../../components-v2/Loading'
import { useInfiniteQuery } from '@tanstack/react-query'
import { filterConsecutiveCons } from '../../utils/postHelper'

const HomeContainer = ({
  setPostModalVisible,
  setSelectTopicPostCreateModalVisible,
  setEditPostModalVisible,
  editPostModalVisible,
  selectPostDetails,
  postEditRules,
  addPostsToRedux,
  // getAllTopics,
  // topics,
  user,
  getMe,
  getTrustFeedChanges,
  trustFeedChanges,
  trustFeedContrasts,
  isTrustFeedSeen,
  trustFeedContrastsT,
  permissions,
  analyticsPosts,
}: any) => {
  // function getWindowSize() {
  //   const { innerWidth, innerHeight } = window
  //   return { innerWidth, innerHeight }
  // }

  // const [windowSize, setWindowSize] = useState(getWindowSize())

  const callTrustFeed = (lastLogin: Date) => {
    const date = new Date(lastLogin)
    const today = new Date()
    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()

    if (!isToday && lastLogin) {
      getTrustFeedChanges()
      trustFeedContrasts()
    }
    return
  }

  useEffect(() => {
    if (isAuthenticated()) {
      getMe()
    } else {
      // navigate(
      //   '/topic/324d5030-1169-44c4-a88a-97733ac3f6e1?popup=landingPopup&defaultOpen=1'
      // )

      navigate('/landing')
    }
  }, [])

  useEffect(() => {
    // getAllTopics()
    user?.lastLogin !== null && callTrustFeed(user?.lastLogin)
  }, [user?.lastLogin])

  // useEffect(() => {
  //   function handleWindowResize() {
  //     setWindowSize(getWindowSize())
  //   }

  //   window.addEventListener('resize', handleWindowResize)

  //   return () => {
  //     window.removeEventListener('resize', handleWindowResize)
  //   }
  // }, [])

  useEffect(() => {
    if (!isTrustFeedSeen) {
      if (trustFeedContrastsT?.length > 0 || trustFeedChanges?.length > 0) {
        navigate('/trust')
      }
    }
  }, [trustFeedChanges, trustFeedContrasts])

  const postEditRulesFunction = (post: any) => {
    postEditRules(post?.id)
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [postTypeWheelOpen, setPostTypeWheelOpen] = useState(false)
  const [orgWheelOpen, setOrgWheelOpen] = useState(false)
  const [filterPostType, setFilterPostType] = useState('all')
  const [filterOrganization, setFilterOrganization] = useState('all')

  // const fetchTopicsWithPosts = useHome(
  //   (state: any) => state.fetchTopicsWithPosts
  // )
  // const topicsWithPosts = useHome((state: any) => state.topicsWithPosts)
  // const isLoading = useHome((state: any) => state.isLoading)
  // console.log('🚀 ~ topicsWithPosts:', topicsWithPosts)

  // const {
  //   ref: loadMoreRef,
  //   inView,
  //   entry,
  // } = useInView({
  //   triggerOnce: false,
  // })

  // useEffect(() => {
  //   if (inView) {
  //     fetchTopicsWithPosts({
  //       postType: filterPostType,
  //       org: filterOrganization,
  //     })
  //   }
  // }, [filterPostType, filterOrganization, inView])

  const { data, fetchNextPage, isFetching, isLoading, refetch } =
    useInfiniteQuery({
      queryKey: ['posts', filterPostType, filterOrganization],
      queryFn: getNioozeWrapper,
      initialPageParam: 1,
      getNextPageParam: getNextPageParam,
    })

  let topicsWithPosts = []

  for (const page of data?.pages || []) {
    for (const item of page?.homeFeed || []) {
      topicsWithPosts.push(item)
    }
  }

  const {
    ref: loadMoreRef,
    inView,
    entry,
  } = useInView({
    /* Optional options */
    threshold: 0,
    onChange(inView, entry) {
      if (inView) {
        fetchNextPage()
      }
    },
  })

  return (
    <>
      <Header />
      <AnalyticsTrackerWrapper>
        <div className="mx-auto flex w-full max-w-[2000px] mx-desktop:flex-col">
          {/* Left div */}
          <div
            className="min-h-44 overflow-hidden overflow-x-hidden mx-desktop:hidden"
            style={{
              flex: '0 0 30%',
            }}
          >
            <SidebarContent className="fixed" />
          </div>

          {/* Middle div */}
          <div
            className="flex min-h-screen flex-col items-center border-r border-line mx-desktop:border-r-0 relative"
            style={{
              flex: '0 0 40%',
            }}
          >
            <div className="absolute -left-10 mx-desktop:left-3 top-0 h-full mx-mobile:h-fit">
              <div className="sticky mx-desktop:fixed mx-desktop:right-3 desktop:top-16 mx-desktop:bottom-3 z-30">
                <FilterWheelEntry
                  currentPostType={filterPostType}
                  setPostType={(type: string) => {
                    setFilterOrganization('all')
                    setFilterPostType(type)
                  }}
                  currentOrgType={filterOrganization}
                  setOrgType={(org: string) => {
                    setFilterPostType('all')
                    setFilterOrganization(org)
                  }}
                  // setFilterOrder={setFilterOrder}
                  // filterOrder={filterOrder}
                  // setFilterTime={setFilterTime}
                  // filterTime={filterTime}
                  postTypeWheelOpen={postTypeWheelOpen}
                  setPostTypeWheelOpen={setPostTypeWheelOpen}
                  orgWheelOpen={orgWheelOpen}
                  setOrgWheelOpen={setOrgWheelOpen}
                  // disabledPostTypes={disabledItems.postTypes}
                  // disabledOrgTypes={disabledItems.orgTypes}
                />
              </div>
            </div>

            {topicsWithPosts?.length > 0 ? (
              (topicsWithPosts || []).map((x: any, i: number) => {
                return (
                  <div key={i} className="w-full">
                    <div className="flex justify-center">
                      <div className="w-[390px]">
                        <div
                          className="flex cursor-pointer justify-between rounded-t-sm bg-line px-[12px] py-[10px] transition-all hover:bg-[#bfbfbf]"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              behavior: 'smooth',
                            })
                            navigate(
                              `/topic/${x.id !== 'local' ? x.id : 'local'}`
                            )
                          }}
                        >
                          <div className="w-full">
                            <div className="flex items-center justify-between text-[14px] font-semibold leading-4">
                              {x.title}

                              <TopicFollowButton
                                // cb={getAllTopics}
                                cb={refetch}
                                topicId={x.id}
                              />
                            </div>
                            <div className="flex items-center justify-between gap-4 pt-[3px]">
                              <p className="overflow-hidden text-ellipsis whitespace-nowrap text-xs">
                                {/*{x?.address?.isObject()*/}
                                {x?.address
                                  ? locationToDisplay(x?.address)
                                  : x?.locationId}
                              </p>
                              <div className="mt-1 flex h-[12px] flex-shrink-0 items-end whitespace-nowrap text-[12px] font-semibold text-border underline underline-offset-4">
                                See all posts
                              </div>
                            </div>
                          </div>
                        </div>

                        <EntryDialog
                          trigger={
                            <div className="flex h-[28px] w-full max-w-[390px] cursor-pointer items-center gap-[6px] rounded-b-lg border border-line bg-[#F5F5F5] px-[12px] transition-all hover:bg-line/50 mx-mobile:w-full">
                              <img src={AddSVG} className="h-4 w-4" />
                              <p className="text-[11px] text-border">
                                Create content
                              </p>
                            </div>
                          }
                          disableTopic={permissions?.topicCreation}
                          articleAllow={permissions?.articleCreation}
                          quotesAllow={permissions?.quotesCreation}
                          parentTopicId={x.id}
                          cb={() => {
                            // getAllTopics()
                            refetch()
                          }}
                        />
                      </div>
                    </div>

                    {x?.posts?.length > 0 && (
                      <div className="mt-6 flex w-full flex-col gap-6 mx-mobile:px-5">
                        {filterConsecutiveCons(x?.posts)?.map(
                          (p: any, i: number) => {
                            return (
                              <PostWithContrast
                                analyticsPosts={analyticsPosts}
                                addPostsToRedux={addPostsToRedux}
                                userLocation={user?.userLocations?.address}
                                isLocationModerator={p?.isLocationModerator}
                                post={p}
                                key={i}
                                pageLoad={() => {
                                  // getAllTopics()
                                  refetch()
                                }}
                              />
                            )
                          }
                        )}
                      </div>
                    )}
                    {x?.posts?.length > 1 ? (
                      <p
                        className={
                          'mx-auto mb-[15px] mt-[20px] flex w-fit cursor-pointer items-center rounded-md p-1 text-center font-inter text-xs font-semibold leading-[14.52px] text-[#2443B2] transition-all hover:bg-line'
                        }
                        onClick={() => {
                          navigate('topic/' + x.id)
                        }}
                      >
                        Read more <ChevronRight size={16} />
                      </p>
                    ) : (
                      <p
                        className={
                          'mx-auto mb-[15px] mt-[20px] flex w-fit cursor-pointer items-center rounded-md p-1 text-center font-inter text-xs font-semibold leading-[14.52px] text-[#2443B2] transition-all hover:bg-line'
                        }
                      ></p>
                    )}

                    {x?.posts?.length < 1 && (
                      <div className="mt-2 text-center text-xs text-border">
                        No Posts
                      </div>
                    )}
                  </div>
                )
              })
            ) : (
              <div className="mt-10 text-center text-xs text-border">
                No Posts
              </div>
            )}

            {/* Loading */}
            {isFetching && <Loading />}

            {/* Intersection Observer for load more */}
            <div ref={loadMoreRef} className="h-5 w-full"></div>
          </div>

          {/* Right div */}
          <div
            className="relative min-h-44 mx-desktop:hidden"
            style={{
              flex: '0 0 30%',
            }}
          ></div>
        </div>
      </AnalyticsTrackerWrapper>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.login.user,
    userLoading: state.login.userLoading,
    userError: state.login.userError,
    permissions: state?.login?.user?.roles?.permissions,

    topics: state.topics.topics,
    trustFeedChanges: state.trustFeed.trustFeedChanges,
    trustFeedContrastsT: state.trustFeed?.trustFeedContrasts,
    isTrustFeedSeen: state.trustFeed.isTrustFeedSeen,
    analyticsPosts: state.analytics.viewed_posts,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getMe: () => {
      dispatch(getMe())
    },
    getAllTopics: () => {
      dispatch(getAllTopics())
    },
    selectPostDetails: (post: any) => {
      dispatch(selectPostDetails(post))
    },
    postEditRules: (postId: any) => {
      dispatch(postEditRules(postId))
    },
    addPostsToRedux: (postId: any) => {
      dispatch(addPostsToRedux(postId))
    },
    getTrustFeedChanges: () => {
      dispatch(getTrustFeedChanges())
    },
    trustFeedContrasts: () => {
      dispatch(trustFeedContrasts())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)

import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { z } from 'zod'
import {
  RadioGroup,
  RadioGroupItem,
} from '../../components-v2/shadcn/radio-group'

import { toast } from 'sonner'
import { getEmailSettingsByToken, saveEmailSettings } from '../Settings/helper'
import { Switch } from '../../components-v2/shadcn/switch'
import Button from '../../components-v2/Button'

type Props = {
  token?: string
}

const schema = z.object({
  // daily: z.boolean(),
  // twoDaily: z.boolean(),
  // weekly: z.boolean(),

  frequency: z.string(),

  engagements: z.boolean(),
  trustfeed: z.boolean(),
  topPicks: z.boolean(),
})

type SchemaType = z.infer<typeof schema>

export default function EmailSettingForm({ token }: Props) {
  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {
      // daily: true,
      engagements: true,
      topPicks: true,
      trustfeed: true,
      frequency: 'none',
      // twoDaily: true,
      // weekly: true,
    },
  })

  const frequencyCheckboxes = [
    {
      name: 'none',
      label: 'None',
    },
    {
      name: 'daily-if-no-visit',
      label: 'Daily, but only if I did not visit niooze.com that day',
    },
    {
      name: 'daily',
      label: 'Daily',
    },
    {
      name: 'twoDaily',
      label: '2-Daily',
    },
    {
      name: 'weekly',
      label: 'Weekly',
    },
  ]

  const sectionCheckboxes = [
    {
      name: 'engagements',
      label: 'My posts engagement',
    },
    {
      name: 'trustfeed',
      label: 'Trust feed',
    },
    {
      name: 'topPicks',
      label: 'Top picks & topics',
    },
  ]

  const { data, isLoading } = useQuery({
    queryKey: ['emailSettings', token],
    queryFn: getEmailSettingsByToken,
    enabled: !!token,
  })

  // const { data, isLoading, error } = useQuery(
  //   ['emailSettings', token],
  //   () => () => {
  //     if (token) {
  //       getEmailSettingsByToken(token)
  //     }
  //   }
  // )

  useEffect(() => {
    if (data) {
      // setValue('daily', data.daily)
      // setValue('twoDaily', data.twoDaily)
      // setValue('weekly', data.weekly)
      setValue('frequency', data.frequency)
      setValue('engagements', data.engagements)
      setValue('topPicks', data.topPicks)
      setValue('trustfeed', data.trustfeed)
    }
  }, [data])
  return (
    <form className="flex flex-col gap-5 mt-5 text-xs  border rounded-lg p-5">
      <Controller
        name={'frequency'}
        control={control}
        render={({ field }) => {
          return (
            <div className="flex flex-col gap-3">
              <h2 className="font-semibold text-xs">Frequency</h2>
              <RadioGroup onValueChange={field.onChange} value={field.value}>
                {frequencyCheckboxes.map((x) => {
                  return (
                    <div className="flex items-center space-x-2" key={x.name}>
                      <RadioGroupItem value={x.name} id={x.name} />
                      <label htmlFor={x.name} className="cursor-pointer">
                        {x.label}
                      </label>
                    </div>
                  )
                })}
              </RadioGroup>
            </div>
          )
        }}
      />

      <div className="flex flex-col gap-3">
        <h2 className="font-semibold text-xs">Sections</h2>
        {sectionCheckboxes.map((x: any) => {
          return (
            <Controller
              name={x.name}
              control={control}
              render={({ field }) => {
                return (
                  <div className="flex items-center space-x-2">
                    <Switch
                      id={field.name}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <label
                      htmlFor={field.name}
                      className="cursor-pointer text-xs"
                    >
                      {x.label}
                    </label>
                  </div>
                )
              }}
            />
          )
        })}
      </div>

      <div className="flex justify-end">
        {/* <p
          className="cursor-pointer"
          onClick={() => {
            // setValue('daily', false)
            // setValue('twoDaily', false)
            // setValue('weekly', false)

            setValue('frequency', 'none')

            setValue('engagements', false)
            setValue('topPicks', false)
            setValue('trustfeed', false)
          }}
        >
          Unsubscribe
        </p> */}

        <Button
          disabled={isSubmitting}
          onClick={handleSubmit(async (values) => {
            try {
              console.log('🚀 ~ onClick={handleSubmit ~ value:', values)
              // await createHouseguest(value)

              if (token) {
                await saveEmailSettings(token, values)
              } else {
                throw new Error('No Token')
              }

              toast.success(`Saved`)
            } catch (error) {
              console.log(error)
              toast.error('Something went wrong')
            }
          })}
        >
          Save
        </Button>
      </div>
    </form>
  )
}

import React, { ReactNode, useEffect } from 'react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../shadcn/dropdown-menu'
import { Avatar, AvatarFallback, AvatarImage } from '../shadcn/avatar'
import { connect, useSelector } from 'react-redux'
import { User } from '../../types/user'
import PlusSVG from '../../assets/icons/plus-black.svg'
import { Link } from 'react-router-dom'
import { getInitials } from '../Post/helpers'
import { Circle } from 'lucide-react'
import { useNotifications } from '../../containers/NotificationsPage/notifications.store'

type Props = {
  trigger: ReactNode
  isAdmin?: boolean
  houseGuestPermissions?: any
}

function DropdownNav({ trigger, isAdmin, houseGuestPermissions }: Props) {
  const currentUser: User | null = useSelector((state: any) => state.login.user)

  const loadSummary = useNotifications((state: any) => state.loadSummary)
  const notifications = useNotifications((state: any) => state.notifications)

  useEffect(() => {
    loadSummary()
  }, [])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="relative flex gap-1 items-center">
        {notifications?.length > 0 && (
          <div className="  bg-red-500 h-4 w-4 rounded-full flex items-center justify-center text-[9px] font-bold text-white  left-0 top-0 animate-pulse">
            {notifications.length}
          </div>
        )}
        {trigger}

        {/* {notifications?.length > 0 && (
          <div className="  bg-red-500 h-2 w-2 -top-[4px] -right-[8px] rounded-full absolute animate-pulse"></div>
        )} */}
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem asChild>
          <Link
            to={`/profiledetails`}
            className="flex items-center gap-2 w-[250px]"
          >
            <Avatar className="rounded-full object-cover">
              <AvatarImage src={currentUser?.imageUrl} />
              {/* TODO: Render 2 letters of the name */}
              <AvatarFallback>{getInitials(currentUser?.name)}</AvatarFallback>
            </Avatar>

            <div className="flex-grow">
              <p className="font-semibold text-xs">{currentUser?.name}</p>
              <p className="text-xs font-normal text-border">
                {currentUser?.email}
              </p>
            </div>
          </Link>
        </DropdownMenuItem>

        {currentUser?.organizationUsers?.map((x, i) => {
          return (
            <DropdownMenuItem key={i} asChild>
              <Link
                to={`/organizationdetails/${x.id}`}
                className="flex items-center gap-2 w-[250px]"
              >
                <Avatar className="rounded-full object-cover">
                  <AvatarImage src={x?.imageUrl} />
                  {/* TODO: Render 2 letters of the name */}
                  <AvatarFallback>{getInitials(x?.name)}</AvatarFallback>
                </Avatar>

                <div className="flex-grow">
                  <p className="font-semibold text-xs">{x.name}</p>
                  <p className="text-xs font-normal text-border">{x.type}</p>
                </div>
              </Link>
            </DropdownMenuItem>
          )
        })}

        <DropdownMenuItem asChild>
          <Link
            to={`/createorganization`}
            className="flex gap-1 justify-center"
          >
            <img src={PlusSVG} className="w-6 h-6" />
            <p className="text-xs">Create new organizations</p>
          </Link>
        </DropdownMenuItem>

        {/* <div className="w-[250px] h-48 bg-red-200">
          <div className="flex items-center w-full gap-2">
            <Avatar className="">
              <AvatarImage src={'data?.profileImageUrl'} />
         
              <AvatarFallback>AB</AvatarFallback>
            </Avatar>

            <div className="flex-grow">
              <p className="font-semibold text-xs">scsc</p>
              <p className="text-xs font-normal text-border">scsscscs</p>
            </div>
          </div>
        </div> */}
        <DropdownMenuSeparator />

        <DropdownMenuItem asChild>
          <Link
            to={'/notifications'}
            className="relative flex items-center gap-2 w-full"
          >
            Notifications
            {notifications?.length > 0 && (
              <div className="  bg-red-500 h-4 w-4 rounded-full flex items-center justify-center text-[9px] font-bold text-white">
                {notifications.length}
              </div>
            )}
          </Link>
        </DropdownMenuItem>

        {isAdmin && (
          <DropdownMenuItem asChild>
            <Link to={'/adduser'}>Add User</Link>
          </DropdownMenuItem>
        )}
        {houseGuestPermissions && (
          <DropdownMenuItem asChild>
            <Link to={'/create-houseguest'}>Create houseguest</Link>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem
          onClick={() => {
            if (window.confirm('Are you sure you want to logout?')) {
              localStorage.setItem('token', '')
              window.location.reload()
            }
          }}
        >
          Logout
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const mapStateToProps = (state: any) => {
  return {
    isAdmin: state?.login?.user?.admin,
    houseGuestPermissions:
      state?.login?.user?.roles?.permissions?.houseGuestCreation,
  }
}

export default connect(mapStateToProps, null)(DropdownNav)

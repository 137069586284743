import Loading from '../Loading'
import PartyCard from '../EntryDialog/dialogs/AddTopic/PartyCard'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import debounce from '../../utils/debounce'
import { searchUsers } from '../../services/user'

export default function UserSearch() {
  const navigate = useNavigate()
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)

  const debouncedOnChange = useCallback(
    debounce((value) => {
      // console.log('Input value:', value)
      if (value) {
        setLoading(true)
        searchUsers({
          text: value,
        })
          .then((data) => {
            setUsers(data)
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        //when the user clear the text box
        setUsers([]) //list should be cleared
      }
    }, 300),
    []
  )

  return (
    <div className={'z z-20 ml-3 mr-2 bg-background md:mr-3 lg:w-[272px]'}>
      <div className="flex w-full max-w-md items-center rounded-full border-[1px] border-primary bg-white p-1">
        <svg
          className="text-customBlue ml-[5px] h-8 w-8 "
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-4.35-4.35m1.2-5.65a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0z"
          ></path>
        </svg>
        <input
          className="placeholder-italic placeholder:text-customBorder ml-[11px] w-full text-primary focus:outline-none"
          type="text"
          placeholder="Search"
          onChange={(event) => {
            event.stopPropagation()
            debouncedOnChange(event.target.value)
          }}
        />
      </div>

      {loading && (
        <div className="mt-2 flex justify-center">
          <Loading />
        </div>
      )}

      <div className="border- absolute z-20 mt-2 flex w-[255px] flex-col gap-[19px] rounded-lg bg-background pb-2 pl-2 pr-2 shadow-xl md:w-[255px] lg:w-[273px]">
        {users?.map((x: any) => {
          return (
            <PartyCard
              name={x.name}
              roleName={x.roles?.name}
              profileImageUrl={x?.imageUrl}
              location={x?.userLocations?.name}
              organization={x?.organizationUsers?.[0]?.name}
              onClickCard={() => navigate('/user/' + x?.id)}
              isVerified={x?.verified}
              isPublic={x?.public}
            />
          )
        })}
      </div>
    </div>
  )
}

import { useCallback, useEffect, useState } from 'react'
import { Input } from '../shadcn/input'
import debounce from '../../utils/debounce'
import { fetchLocationsAndTopics } from './helper'
import Loading from '../Loading'
import { allowOnlyOneLevelBelowCity } from '../../utils/osmHelpers'

type Props = {
  onSelectLocation: (v: any) => void
  setLocationObj: any
  setLocation: any
  anyLevelOfLocation?: any
}

export default function LocationSelect({
  onSelectLocation,
  setLocationObj,
  setLocation,
  anyLevelOfLocation = false,
}: Props) {
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>()
  // console.log('🚀 ~ TopicSelectDialogContent ~ data:', data)

  const debouncedOnChange = useCallback(
    debounce((value) => {
      setLoading(true)
      if (value) {
        fetch(
          'https://nominatim.openstreetmap.org/search?format=json&countrycodes=us&addressdetails=1&accept-language=us&q=' +
            value,
          {
            method: 'GET',
            redirect: 'follow',
          }
        )
          .then((response) => response.json())
          .then((result) => {
            setData(
              !anyLevelOfLocation ? allowOnlyOneLevelBelowCity(result) : result
            )
            setLoading(false)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }, 300),
    []
  )

  return (
    <div className="p-5">
      <Input
        className=""
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value)
          debouncedOnChange(e.target.value)
        }}
      />
      <div className="flex gap-1">
        <div className="mt-1 flex-1 text-xs">
          <h2 className="ml-1 text-border">Locations</h2>
          <ul className="mt-2 flex h-[300px] flex-col gap-1 overflow-auto font-bold">
            {loading && (
              <div className="mt-5 flex justify-center">
                <Loading />
              </div>
            )}
            {data &&
              data?.map((x: any) => {
                return (
                  <li
                    key={x?.place_id}
                    className="cursor-pointer rounded-sm p-1 transition-all hover:bg-gray-100"
                    onClick={() => {
                      onSelectLocation({
                        ...x?.address,
                        display_name: x?.display_name,
                      })
                      setLocation({
                        address: x?.address,
                        addressType: x?.addresstype,
                        coordinates: { latitude: x?.lat, longitude: x?.lon },
                        display_name: x?.display_name,
                      })
                      setLocationObj(x?.display_name)
                    }}
                  >
                    {x?.display_name}
                    <div key={x?.place_id} className={'mt-0.5'}>
                      <span
                        className={
                          'rounded bg-gray-200 p-0.5 font-normal text-gray-500'
                        }
                      >
                        {x?.addresstype}
                      </span>
                    </div>
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import SignUpHeader from '../../components/SignUpHeader'
import OnBoardButton from '../../components/OnBoardButton'

import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import { useNavigate } from 'react-router-dom'

import './index.css'
import {
  addUserCredentials,
  getMe,
  userCreateOnAgreement,
} from '../../redux/login/actions'
import axios from 'axios'
import Button from '../../components-v2/Button'
import Loading from '../../components-v2/Loading'
import { termsOfServiceHTML } from '../TermsOfService/termsOfServiceHTML'
import PublicHeader from '../../components-v2/Header/PublicHeader'
import { toast } from 'sonner'
const AcceptTerms = ({ userCreateOnAgreement, userDetails, getMe }: any) => {
  const navigate = useNavigate()

  const [disableSubmit, setDisableSubmit] = useState(false)

  const [acceptTerms, setAcceptTerms] = useState(false)
  const [acceptTermsError, setAcceptTermsError] = useState('')

  const acceptTermsFunction = () => {
    const tempUserDetails = { userTerms: acceptTerms, ...userDetails }

    setDisableSubmit(true)

    // if (acceptTerms) {
    userCreateOnAgreement(
      tempUserDetails.email,
      tempUserDetails.password,
      tempUserDetails.firstName,
      tempUserDetails.lastName,
      tempUserDetails.location,
      tempUserDetails.userType,
      tempUserDetails.description,
      tempUserDetails.imageUrl,
      tempUserDetails.userId,
      tempUserDetails.userTerms,
      tempUserDetails.phoneNumber,
      tempUserDetails.birthday,
      toast,
      acceptTerms
    )
      .then((res: any) => {
        setDisableSubmit(false)
        if (acceptTerms) {
          axios
            .post(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
              email: tempUserDetails.email,
              password: tempUserDetails.password,
            })
            .then((res) => {
              setDisableSubmit(false)
              localStorage.setItem('token', res.data.token)
              getMe()
              navigate('/')
            })
        }
      })
      .catch((err: any) => {
        console.log('test')
      })

    // console.log('🚀 ~ acceptTermsFunction ~ res:', res)
    // navigate('/login')
    setAcceptTermsError('')
    // } else {
    setDisableSubmit(false)
    setAcceptTermsError('please accept the terms of services')
    // }
  }

  useEffect(() => {
    if (acceptTerms) {
      setAcceptTermsError('')
    }
  }, [acceptTerms])

  return (
    <div className="main-div-accept-screen min-h-screen overflow-y-hidden pb-10">
      {/*main div*/}
      {/* <SignUpHeader /> */}
      <PublicHeader />
      {/*header with check logo*/}
      <div className={' text-center'}>
        {/*header*/}
        <text className={'check-text-accept-screen'}>
          Please read below terms of service
        </text>
      </div>
      <div
      // style={{
      //   display: 'flex',
      //   justifyContent: 'center',
      //   marginBottom: '50px',
      //   marginTop: '684px',
      // }}
      >
        <div className={'middle-flex-accept-screen mx-auto mt-5'}>
          <div
            className="text-white h-[500px] overflow-y-scroll"
            dangerouslySetInnerHTML={{ __html: termsOfServiceHTML }}
          ></div>
          <div className="mb-15 flex items-center justify-center mt-5">
            <label className="mb-3 flex items-center">
              <input
                type="checkbox"
                // checked={checked}
                onChange={() => {
                  setAcceptTerms(!acceptTerms)
                }}
                className="mr-2"
              />
              <span className="text-white text-sm">
                I’ve read and I accept the terms of service
              </span>
            </label>
          </div>
          <p className="text-red-500 text-sm text-center">{acceptTermsError}</p>
          {/* <OnBoardButton
            onClick={() => {
              acceptTermsFunction()
            }}
            width={'310px'}
            buttonName={'Continue'}
            disabled={false}
          /> */}
          <Button
            disabled={false}
            className="text-black w-full flex justify-center items-center gap-2 transition-all"
            onClick={() => {
              acceptTermsFunction()
            }}
          >
            Continue {disableSubmit && <Loading />}
          </Button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    userDetails: state.login.userDetails,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    userCreateOnAgreement: (
      email: any,
      password: any,
      firstName: any,
      lastName: any,
      location: any,
      userType: any,
      aboutYourSelf: any,
      imageUrl: any,
      userId: any,
      userTerms: any,
      phoneNumber: any,
      birthday: any,
      toast: any,
      terms: any
    ) => {
      return dispatch(
        userCreateOnAgreement(
          email,
          password,
          firstName,
          lastName,
          location,
          userType,
          aboutYourSelf,
          imageUrl,
          userId,
          userTerms,
          phoneNumber,
          birthday,
          toast,
          terms
        )
      )
    },
    getMe: () => {
      dispatch(getMe())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AcceptTerms)

import React from 'react'
import LogoSVG from '../../assets/icons/check-logo.svg'
import PlusSVG from '../../assets/icons/plus.svg'
import MenuSVG from '../../assets/icons/fi-rr-menu-burger.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import EntryDialog from '../EntryDialog/EntryDialog'
import DropdownNav from './DropdownNav'
import { cn } from '../../utils/cn'
import { getAllTopics } from '../../redux/topics/actions'
import { useDispatch, useSelector } from 'react-redux'
import FilterDialog from '../FilterWheel/postType/PostTypeWheelDialog'

type Props = {
  noAuthClick: () => void
}

export default function NoAuthHeader({ noAuthClick }: Props) {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <div className="h-[43px]">
      <header className="flex items-center justify-between bg-primary h-[43px] px-[10px] fixed w-full z-50">
        {/* Logo */}
        <img src={LogoSVG} className="w-6 h-6" />

        {/* Middle */}
        <div className="flex gap-[29px] mx-mobile:gap-4 items-center">
          <p
            onClick={noAuthClick}
            className={cn(
              'min-w-28 mx-mobile:min-w-0 hover:font-bold transition-all text-end text-white mx-mobile:text-[14px] cursor-pointer',
              {
                'font-bold': location.pathname === '/',
              }
            )}
          >
            Home feed
          </p>

          <div className="cursor-pointer flex-shrink-0" onClick={noAuthClick}>
            <img src={PlusSVG} className="w-6 h-6 flex-shrink-0" />
          </div>

          <p
            className={cn(
              'min-w-28 mx-mobile:min-w-0 hover:font-bold transition-all text-start text-white mx-mobile:text-[14px] cursor-pointer',
              {
                'font-bold': location.pathname === '/trust',
              }
            )}
            onClick={noAuthClick}
          >
            Trust feed
          </p>
        </div>

        {/* Dropdown */}
        <div className="flex items-center">
          <img
            src={MenuSVG}
            className="w-6 h-6 cursor-pointer"
            onClick={noAuthClick}
          />
        </div>
      </header>

      {/*mobile view*/}
      <div
        className={
          'fixed ml-2 bottom-0 mb-2 bg-[#d3e6c6] border-r-2 rounded-lg  desktop:hidden cursor-pointer hover:bg-[#c7edad] z-50'
        }
        style={{
          boxShadow: ' 0px 0px 10px 10px rgba(255,255,255,1)',
        }}
      >
        <p
          style={{ fontWeight: 'bold' }}
          className={'text-[#2443B2] p-2'}
          onClick={() => {
            navigate(`/topic/feedback`)
          }}
        >
          Give Feedback
        </p>
      </div>

      {/*desktop view*/}
      <div
        className={
          'fixed mt-12 mr-3 right-0 bg-[#d3e6c6] border-r-2 rounded-lg mx-desktop:hidden cursor-pointer hover:bg-[#c7edad] z-50'
        }
        onClick={() => {
          navigate(`/topic/feedback`)
        }}
      >
        <p style={{ fontWeight: 'bold' }} className={'text-[#2443B2] p-2'}>
          {' '}
          Give Feedback
        </p>
      </div>
    </div>
  )
}
